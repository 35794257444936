<template>

  <div class="wrapper center-text">
    <h2 class="top-padding">ABOUT YOUR HONEY</h2>
    <p class="informational-text larger-info-text" style="margin-bottom:2.5rem;">Batch {{this.$route.params.code}}</p>
  </div>


  <div class="grid justify-content-center bottom-fix">

    <div class="col-12 md:col-6 product-card-wrapper" data-aos="zoom-in" data-aos-once="true">
      <img :src="jarImage" alt="Wero Honey" style="margin: 0 auto; "/>
    </div>

    <div class="col-12 center-text" data-aos="zoom-in" data-aos-once="true">
      <p><a :href="this.certificatePDFPath" target="_blank" class="certificate-link">View Batch Certificate</a></p>
      <br>
    </div>



    <div class="col-12 md:col-10 center-text">




      <div class="grid" style="align-items: stretch">

        <!-- MGO -->
        <div class="col-12 md:col-6 lg:col-3 product-card-wrapper" data-aos="zoom-in" data-aos-once="true">
          <Card class="product-card">
            <template #title>
              <div class="center-text card-title">
                <span class="block">MGO</span>
              </div>
              <div class="col-12">
                <Knob v-model="this.batchResults.methylglyoxal" :min="0" :max="1500" valueColor="#000000" readonly/>
                <p class="informational-text knob-text">{{this.batchResults.methylglyoxalUnits}}</p>
              </div>
            </template>
            <template #subtitle>
              <div class="center-text card-subtitle">
                Methylglyoxal
              </div>
            </template>
            <template #content>
              <p class="honey-card-text">MGO is the active ingredient in Mānuka honey. Honey with higher MGO have higher levels of enzymes and antibacterial properties. As the MGO content increases, so do the medicinal benefits of the honey.</p>
            </template>
          </Card>
        </div>

        <!-- DHA -->
        <div class="col-12 md:col-6 lg:col-3 product-card-wrapper" data-aos="zoom-in" data-aos-once="true">
          <Card class="product-card">
            <template #title>
              <div class="center-text card-title">
                <span class="block">DHA</span>
              </div>
              <div class="col-12">
                <Knob v-model="this.batchResults.dihydroxyacetone" :min="0" :max="1500" valueColor="#000000" readonly/>
                <p class="informational-text knob-text">{{this.batchResults.dihydroxyacetoneUnits}}</p>
              </div>
            </template>
            <template #subtitle>
              <div class="center-text card-subtitle">
                Dihydroxyacetone
              </div>
            </template>
            <template #content>
              <p class="honey-card-text">DHA is unique to Mānuka nectar. It slowly converts to MGO over time. Measuring the DHA in freshly harvested honey tells us about the honey's potential to rise in MGO levels after storage. Higher DHA leads to higher MGO over time.</p>
            </template>
          </Card>
        </div>

        <!-- HMF -->
        <div class="col-12 md:col-6 lg:col-3 product-card-wrapper" data-aos="zoom-in" data-aos-once="true">
          <Card class="product-card">
            <template #title>
              <div class="center-text card-title">
                <span class="block">HMF</span>
              </div>
              <div class="col-12">
                <Knob v-model="this.batchResults.hydroxyMethylFurfural" :min="0" :max="50" valueColor="#000000" readonly/>
                <p class="informational-text knob-text">{{this.batchResults.hydroxyMethylFurfuralUnits}}</p>
              </div>
            </template>
            <template #subtitle>
              <div class="center-text card-subtitle">
                Hydroxymethylfurfural
              </div>
            </template>
            <template #content>
              <p class="honey-card-text">HMF is an indicator of honey freshness. Typically, the fresher the honey, the lower the level of HMF. HMF tends to rise in concentration because of aging and overheating the honey during processing.</p>
            </template>
          </Card>
        </div>

        <!-- DNA -->
        <div class="col-12 md:col-6 lg:col-3 product-card-wrapper" data-aos="zoom-in" data-aos-once="true">
          <Card class="product-card">
            <template #title>
              <div class="center-text card-title">
                <span class="block">DNA</span>
                <div class="col-12">
                  <Knob v-model="this.batchResults.manukaDNA" :min="0" :max="50" valueColor="#000000" readonly/>
                  <p class="informational-text knob-text">{{this.batchResults.manukaDNAUnits}}</p>
                </div>
              </div>
            </template>
            <template #subtitle>
              <div class="center-text card-subtitle">
                Mānuka DNA
              </div>
            </template>
            <template #content>
              <p class="honey-card-text">The presence of pollen is an attribute used to identify numerous honey types around the world. Laboratory testing is performed to confirm that the pollen that distinguishes Mānuka honey from other honey types is present in the honey.</p>
            </template>
          </Card>
        </div>
      </div>


    </div>

    <div class="col-12 md:col-10 lg:col-10" data-aos="zoom-in" data-aos-once="true">
      <Card class="product-card">
        <template #title>
          <div class="center-text card-title">
            <span class="block">Location of beehives</span>
          </div>
        </template>
        <template #content>
          <GMapMap :center="center" :zoom="6" map-type-id="terrain" style="width: 100%; height: 800px" :options="{
   zoomControl: true,
   minZoom: 5,
   maxZoom: 10,
   restriction: {latLngBounds: {
                  north: -30,
                  south: -50,
                  west: 158,
                  east: -170}
                  },
   mapTypeControl: false,
   scrollwheel: false,
   streetViewControl: false,
   rotateControl: false,
   fullscreenControl: true,
   disableDefaultUi: false
 }">
            <GMapMarker :key="marker.id" v-for="marker in markers" :position="marker.position" />
          </GMapMap>
        </template>
        <template #footer>
          <p class="informational-text">Locations are indicative only. In order to keep the privacy of our land owners, we do not publish locations to the exact spot.</p>
        </template>
      </Card>
    </div>


  </div>










</template>

<script>

export default {
  name: 'Traceability',

  components: {

  },

  mounted() {

  },

  data() {
    return {


      center: {lat: -41.1, lng: 172.6},
      markers: [
        {
          position: {
            lat: -41.299939, lng: 175.747033
          },
        }
      ],






      batchResults: {
        batchCode: "21PB-S00084",
        jarImageName: "wero clearcuts 300mgo cropped.png",
        classificationMPI: "Monofloral Mānuka Honey",

        phenyllacticAcid: 790,
        phenyllacticAcidUnits: "mg/Kg",

        methoxyacetophenone: 19.7,
        methoxyacetophenoneUnits: "mg/Kg",

        methoxybenzoicAcid: 6.3,
        methoxybenzoicAcidUnits: "mg/Kg",


        hydroxyphenyllacticAcid: 6.3,
        hydroxyphenyllacticAcidUnits: "mg/Kg",


        manukaDNA: 28.29,
        manukaDNAUnits: "Cq",

        dihydroxyacetone: 961,
        dihydroxyacetoneUnits: "mg/Kg",

        hydroxyMethylFurfural: 21.8,
        hydroxyMethylFurfuralUnits: "mg/Kg",

        methylglyoxal: 437,
        methylglyoxalUnits: "mg/Kg",

        geographicLocations: [[-41.406085, 175.466376], [-41.299939, 175.747033]]
      },







    }
  },

  methods: {

  },

  computed: {
    vuex: function () {
      return (this.$store.state);
    },

    jarImage() {
      return require(`../assets/images/${this.batchResults.jarImageName}`);
    },

    certificatePDFPath(){
      return ("../certificates/" + this.batchResults.batchCode + " - CoA.pdf");
    },

    tableData() {
      return ([
        {
          variable: "MGO (Methylglyoxal)",
          units: this.batchResults.methylglyoxalUnits,
          value: this.batchResults.methylglyoxal
        },
        {
          variable: "DHA (Dihydroxyacetone)",
          units: this.batchResults.dihydroxyacetoneUnits,
          value: this.batchResults.dihydroxyacetone
        },
        {
          variable: "HMF (Hydroxymethylfurfural)",
          units: this.batchResults.hydroxyMethylFurfuralUnits,
          value: this.batchResults.hydroxyMethylFurfural
        },
        {
          variable: "Manuka DNA",
          units: this.batchResults.manukaDNAUnits,
          value: this.batchResults.manukaDNA
        }
      ])
    },

  },

  watch: {

  }
}
</script>

<style scoped>

  .honey-card-text{
    font-family: acumin-pro, sans-serif !important;
    font-size: 0.85rem;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 1.4rem;
    text-align: justify;
  }

  .product-card-wrapper{
    display: flex; /*sets equal heights the easy way*/
  }

  .larger-info-text{
    font-weight: 700;
    font-size: 1.4rem;
  }
  /* prevent wrapping of single or small word sentence*/
  span.wrap-together {
    white-space: nowrap ;
  }

  .knob-text{
    margin-top: -12px;
  }

</style>
