<template>

  <div class="grid justify-content-center" :style="'min-height: ' + (vuex.viewHeight - vuex.navFooterCombinedHeight + 10) + 'px;'">
    <div class="col-10">

      <div class="grid top-padding">

        <div class="col-12 lg:col-6 girls-image" style="text-align: center;" data-aos="fade-right" data-aos-once="true">
          <h3 v-if="vuex.isMobile" class="title-text" style="text-align:center; font-family: Roboto Condensed,sans-serif; font-size: 1.7rem">CONTACT</h3>
          <img class="img-border girls-image" :src="contactImage" style="border-radius: 5px; max-width: 100%; margin-top: 35px;"/>
          <p class="informational-text" style="font-size: 1.1rem;">Ana & Ngakita from the Wero Family.</p>
        </div>

        <div class="col-12 lg:col-6" style="text-align: center;" data-aos="fade-up" data-aos-once="true">
          <h3 v-if="!vuex.isMobile" class="title-text" style="text-align:center; font-family: Roboto Condensed,sans-serif; font-size: 1.7rem">CONTACT</h3>
          <p class="informational-text" style="text-align:center; margin-bottom: 20px; margin-top: 0; padding-top:0px; font-size: 1.1rem; line-height: 1.1;">
            Kia Ora and hello,<br>
            Our team here at Wero would love to hear from you,<br>
            Please feel free to leave a message and we will get back to you as soon as we can,<br>
            Ngā Mihinui, thanks to all.<br>
          </p>

          <div class="grid">
            <div class="col-12" style="text-align: left;">
              <InputText v-model="from_name" type="text" class="p-inputtext-sm" placeholder="Your Name" style="width:100%"/>
            </div>
            <div class="col-12" style="text-align: left;">
              <InputText v-model="reply_to" type="text" class="p-inputtext-sm" placeholder="Your Email" style="width:100%"/>
            </div>
            <div class="col-12" style="text-align: left;">
              <Textarea v-model="message" :autoResize="true" rows="10" cols="50" placeholder="Message" style="width: 100%" />
            </div>
            <div class="col-12 ">
              <Button @click="submitContactForm()" label="Submit" style="float:right; background-color: black; margin-bottom: 30px; width: 100%"/>
            </div>
          </div>
        </div>

      </div>

  </div>
  </div>
</template>

<script>

export default {
  name: 'Contact',

  components: {

  },

  mounted() {

  },

  data() {
    return {
      from_name: '',
      message: '',
      reply_to: '',
      contactImage: require("../assets/images/contact us.jpg"),
    }
  },

  methods: {

    submitContactForm(){
      let hasError = false;
      let errorMessage = "";
      if(this.from_name.length == 0){
        errorMessage += "Please leave your name. \n";
        hasError = true;
      }
      if(this.message.length == 0){
        errorMessage += "Please leave your message. \n";
        hasError = true;
      }
      if(this.reply_to.length == 0){
        errorMessage += "Please leave your email. \n";
        hasError = true;
      }

      if(hasError){
        alert(errorMessage);
        return(-1);
      }

      let data = {
        service_id: 'service_vxkhnlh',
        template_id: 'template_jxs7gza',
        user_id: 'lLe2MVFKFTc8W7XKW',
        template_params: {
          'from_name': this.from_name,
          'reply_to': this.reply_to,
          'message': this.message,
        }
      };

      this.axios.post("https://api.emailjs.com/api/v1.0/email/send", data)
          .then(response => function(){
            console.info(response);
          });

      this.from_name = '';
      this.message = '';
      this.reply_to = '';
      alert("Thank-you, we have received your message.");
      this.$router.push('/contact')
    }

  },

  computed: {
    vuex: function () {
      return(this.$store.state);
    },
  },

  watch: {

  }
}
</script>

<style scoped>

.girls-image{
  max-width: 100%;
}

</style>
