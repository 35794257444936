<template>
  <div class="wrapper center-text">
    <h2 class="top-padding">OUR HONEY</h2>
    <div class="surface-card p-4">
      <div class="grid grid-nogutter hidden sm:flex">
        <div class="col-6">
          <img :src="whiteLid" class="bottom-padding" style="float: right; margin-top: 7px" />
        </div>
        <div class="col-6">
          <div class="grid grid-nogutter">
            <div class="col-12" data-aos="fade-left" data-aos-duration="400" data-aos-once="true">
              <p class="informational-text benefit-text text-xl" style="margin-left: -40px">
                <i class="pi pi-map-marker text-2xl"></i>&nbsp;Made in New Zealand
              </p>
            </div>
            <div class="col-12" data-aos="fade-left" data-aos-duration="500" data-aos-once="true">
              <p class="informational-text benefit-text text-xl" style="margin-left: 0px">
                <i class="pi pi-gift text-2xl"></i>&nbsp;Premium Superfood
              </p>
            </div>
            <div class="col-12" data-aos="fade-left" data-aos-duration="650" data-aos-once="true">
              <p class="informational-text benefit-text text-xl" style="margin-left: 0px"><i class="pi pi-home text-2xl"></i>&nbsp;Family Owned</p>
            </div>
            <div class="col-12" data-aos="fade-left" data-aos-duration="800" data-aos-once="true">
              <p class="informational-text benefit-text text-xl" style="margin-left: -40px">
                <i class="pi pi-verified text-2xl"></i>&nbsp;Independently Tested
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 center-text pt-4">
          <p class="informational-text benefit-text" style="float: none" data-aos-duration="900" data-aos-once="true">
            Savor the true essence of Wero Mānuka honey - a mono-floral variety, guaranteed authentic through independent testing.
          </p>
        </div>
        <div class="col-12 center-text pb-6" data-aos="fade-in" data-aos-duration="1200" data-aos-once="true">
          <Button
            @click="routeToWhy"
            label="Learn about the benefits of Mānuka honey"
            icon="pi pi-chevron-right"
            iconPos="right"
            class="p-button-rounded mr-2 p-button-outlined black-button"
          ></Button>
        </div>
      </div>

      <div class="grid grid-nogutter flex sm:hidden">
        <div class="col-12">
          <img :src="whiteLid" class="bottom-padding" />
        </div>
        <div class="col-12">
          <div class="grid grid-nogutter">
            <div class="col-12" data-aos="fade-up" data-aos-duration="400" data-aos-once="true">
              <p class="informational-text benefit-text-mobile text-xl"><i class="pi pi-map-marker text-2xl"></i>&nbsp;Made in New Zealand</p>
            </div>
            <div class="col-12" data-aos="fade-up" data-aos-duration="500" data-aos-once="true">
              <p class="informational-text benefit-text-mobile text-xl"><i class="pi pi-gift text-2xl"></i>&nbsp;Premium Superfood</p>
            </div>
            <div class="col-12" data-aos="fade-up" data-aos-duration="650" data-aos-once="true">
              <p class="informational-text benefit-text-mobile text-xl"><i class="pi pi-home text-2xl"></i>&nbsp;Family Owned</p>
            </div>
            <div class="col-12" data-aos="fade-up" data-aos-duration="800" data-aos-once="true">
              <p class="informational-text benefit-text-mobile text-xl"><i class="pi pi-verified text-2xl"></i>&nbsp;Independently Tested</p>
            </div>
          </div>
        </div>
        <div class="col-12 center-text pt-4">
          <p class="informational-text benefit-text" style="float: none" data-aos-duration="900" data-aos-once="true">
            Savor the true essence of Wero Mānuka honey - a mono-floral variety that is guaranteed authentic through independent testing.
          </p>
        </div>
        <div class="col-12 center-text pb-6" data-aos="fade-up" data-aos-duration="900" data-aos-once="true">
          <Button
            @click="routeToWhy"
            label="Learn about the benefits of Mānuka honey"
            icon="pi pi-chevron-right"
            iconPos="right"
            class="p-button-rounded mr-2 p-button-outlined black-button"
          ></Button>
        </div>
      </div>
    </div>

    <div class="center-text">
      <h2 class="top-padding">OUR PRODUCTS</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductInfo",

  props: {},

  mounted() {},

  data() {
    return {
      whiteLid: require("../../assets/images/white lid.png"),
    };
  },

  methods: {
    routeToWhy: function () {
      // Change to products page - and scroll to top !
      this.$router.push({ name: "Why" });
      window.scrollTo(0, 0);
    },
  },

  computed: {
    vuex: function () {
      return this.$store.state;
    },
  },

  watch: {},
};
</script>

<style scoped>
.wrapper {
  padding-bottom: 2rem;
}
.black-button {
  color: #000000 !important;
  background-color: #ffffff !important;
}

.black-button:hover {
  background-color: #000000 !important;
  color: #ffffff !important;
}

.benefit-text {
  font-weight: 400;
  font-size: 1.3rem;
  float: left;
}

.benefit-text-mobile {
  font-weight: 400;
  font-size: 1.3rem;
  margin-top: 12px;
  margin-bottom: 12px;
}

.larger-info-text {
  font-weight: 700;
  font-size: 1.4rem;
}
/* prevent wrapping of single or small word sentence*/
span.wrap-together {
  white-space: nowrap;
}
</style>
