<template>
  <div class="grid grid-nogutter justify-content-center edge-padding page-wrapper">
    <div class="col-12 md:col-10 lg:col-10 flex-column">
      <product-info></product-info>
      <card-section></card-section>
      <mgo-section v-if="0"></mgo-section>
      <bottom-section v-if="0"></bottom-section>
    </div>
  </div>
</template>

<script>
import ProductInfo from "../components/products/ProductInfo";
import CardSection from "../components/products/CardSection";
import MgoSection from "../components/products/MgoSection";
import BottomSection from "../components/products/BottomSection";
export default {
  name: "Products",

  components: {
    BottomSection,
    MgoSection,
    CardSection,
    ProductInfo,
  },

  mounted() {},

  data() {
    return {
      whiteLid: require("../assets/images/white lid.png"),
    };
  },

  methods: {},

  computed: {
    vuex: function () {
      return this.$store.state;
    },
  },

  watch: {},
};
</script>

<style scoped></style>
