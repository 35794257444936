<template>
  <footer class="footer" id="footerBar">
    <div class="footer-content">
      <div class="flex">
        <img :src="kiwiImage" class="badge"/>
        <img :src="halalImage" class="badge"/>
        <img :src="nzmadeImage" class="badge"/>
      </div>
    </div>
    <div class="copyright">
      <p class="informational-text">&copy; {{ year }} Wero NZ Mānuka Honey</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterBar',

  components: {

  },

  mounted() {
    // Always keep the copyright year as the current year
    this.year = new Date().getFullYear();
  },

  data() {
    return {
      halalImage: require("../assets/images/halal.png"),
      kiwiImage: require("../assets/images/kiwi.png"),
      nzmadeImage: require("../assets/images/nzmade.png"),
      year: null,
    }
  },

  methods: {

  },

  computed: {

  },

  watch: {

  }
}

</script>


<style scoped>

.footer{
  background-color: black;
  padding: 15px 0 5px 0;
  color: #949494;
}

.footer-content{
  margin: 0 auto;
  width: fit-content;
}

.badge{
  max-height: 38px;
}

.copyright{
  text-align: center;
  margin-top: 15px;
}
</style>


