<template>
  <div
    class="grid grid-nogutter justify-content-center edge-padding page-wrapper"
    :style="'min-height: ' + (vuex.viewHeight - vuex.navFooterCombinedHeight + 10) + 'px;'"
  >
    <div class="col-12 md:col-10 lg:col-10 flex-column">
      <div class="grid top-padding">
        <div class="wrapper mb-8">
          <div class="grid grid-no-gutter">
            <div class="col-12">
              <div class="surface-section py-4">
                <div class="text-center bg-no-repeat bg-cover border-round px-6 py-8" :style="'background-image: url(\'' + whyManukaBanner + '\');'">
                  <div class="bg-overlay">
                    <h2 class="text-white mb-3">Why Mānuka Honey?</h2>
                    <p class="text-gray-300 text-xl mt-0 mb-8 line-height-3 informational-text benefit-text text-xl">
                      Discover the Health Benefits of Mānuka Honey
                    </p>
                  </div>
                </div>
                <div class="flex flex-column lg:flex-row px-4 lg:px-6 gap-5 -mt-8">
                  <div class="flex flex-column lg:w-4">
                    <div class="flex align-items-center border-round-top bg-gray-900 text-white h-5rem px-4">
                      <i v-if="0" class="pi pi-sliders-v mr-2 text-2xl"></i>
                      <img :src="immunityIcon" style="max-height: 60px; margin-right: 1em" />
                      <span class="font-semibold text-2xl">Immune System Support</span>
                    </div>
                    <div class="surface-card shadow-2 p-4 flex flex-column justify-content-between flex-1 border-round-bottom">
                      <p class="mt-0 mb-4 line-height-3 card-text">
                        Incorporating Mānuka honey into your daily routine is an easy way to support your immune system and stay healthy.<br /><br />
                        Mānuka honey boosts immunity due to its antioxidants and antibacterial compounds. It can reduce inflammation and fight off
                        bacteria and viruses, supporting the immune system.
                      </p>
                    </div>
                  </div>
                  <div class="flex flex-column lg:w-4">
                    <div class="flex align-items-center border-round-top bg-gray-900 text-white h-5rem px-4">
                      <i v-if="0" class="pi pi-shield mr-2 text-2xl"></i>
                      <img :src="woundcareIcon" style="max-height: 60px; margin-right: 1em" />
                      <span class="font-semibold text-2xl">Wound Healing</span>
                    </div>
                    <div class="surface-card shadow-2 p-4 flex flex-column justify-content-between flex-1 border-round-bottom">
                      <p class="mt-0 mb-4 line-height-3 card-text">
                        Applying Mānuka honey to wounds or using it in wound dressings can speed up healing and prevent infection.<br /><br />
                        Mānuka honey is a natural remedy for wound healing, as it is antibacterial and anti-inflammatory. It can promote tissue
                        regeneration, reduce inflammation, and prevent infection.
                      </p>
                    </div>
                  </div>
                  <div class="flex flex-column lg:w-4">
                    <div class="flex align-items-center border-round-top bg-gray-900 text-white h-5rem px-4">
                      <i v-if="0" class="pi pi-heart mr-2 text-2xl"></i>
                      <img :src="skincareIcon" style="max-height: 60px; margin-right: 1em" />
                      <span class="font-semibold text-2xl">Skincare</span>
                    </div>
                    <div class="surface-card shadow-2 p-4 flex flex-column justify-content-between flex-1 border-round-bottom">
                      <p class="mt-0 mb-4 line-height-3 card-text">
                        Using Mānuka honey skincare products can improve your skin's overall health and appearance.<br /><br />
                        Mānuka honey is rich in antibacterial and anti-inflammatory properties, making it a popular ingredient in skincare products.
                        It can soothe irritated skin, and protect the skin by fighting off acne-causing bacteria.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 mt-4">
            <div class="surface-card shadow-2 border-round p-4 w-full">
              <div class="surface-section">
                <h2 class="mb-3 text-center">Different Grades of Mānuka Honey</h2>
                <div class="flex lg:justify-content-center my-5">
                  <div class="py-3 pr-8 pl-3 w-30rem hidden lg:block">
                    <img :src="ultraHighImage" alt="Image" class="w-full mr-8" />
                  </div>
                  <div class="flex flex-column align-items-center w-2rem">
                    <span
                      class="bg-gray-900 text-0 flex align-items-center justify-content-center border-circle"
                      style="min-width: 2.5rem; min-height: 2.5rem"
                      >1</span
                    >
                    <div class="h-full bg-gray-900" style="width: 2px; min-height: 4rem"></div>
                  </div>
                  <div class="py-3 pl-5 lg:pl-8 pl-3 lg:w-30rem">
                    <div class="font-medium text-2xl mb-3">Premium Grade</div>
                    <p class="mt-0 mb-4 line-height-3 card-text">
                      Our ultra high grade Mānuka honey is the finest quality available, with a potent flavour and maximum medicinal benefits. Ideal
                      for those seeking the highest quality and most effective natural remedy for sore throats, wounds, skincare and immune system
                      support.
                    </p>
                    <img :src="ultraHighImage" alt="Image" class="w-full mt-3 block lg:hidden" />
                  </div>
                </div>
                <div class="flex justify-content-center mb-5">
                  <div class="py-3 pl-5 pr-3 lg:pr-8 lg:pl-3 lg:w-30rem flex-order-1 lg:flex-order-0">
                    <div class="font-medium text-2xl mb-3">High Grade</div>
                    <p class="mt-0 mb-4 line-height-3 card-text">
                      Our high grade Mānuka honey is a popular choice for those seeking a high-quality honey with strong medicinal properties. With a
                      rich and creamy flavour, this honey is perfect for adding to tea, smoothies, or as a natural sweetener.
                    </p>
                    <img :src="highImage" alt="Image" class="w-full mt-3 block lg:hidden" />
                  </div>
                  <div class="flex flex-column align-items-center w-2rem flex-order-0 lg:flex-order-1">
                    <span
                      class="bg-gray-900 text-0 flex align-items-center justify-content-center border-circle"
                      style="min-width: 2.5rem; min-height: 2.5rem"
                      >2</span
                    >
                    <div class="h-full bg-gray-900" style="width: 2px; min-height: 4rem"></div>
                  </div>
                  <div class="py-3 pl-8 pr-3 w-30rem hidden lg:block flex-order-2">
                    <img :src="highImage" alt="Image" class="w-full mr-8" />
                  </div>
                </div>
                <div class="flex justify-content-center mb-5">
                  <div class="py-3 pr-8 pl-3 w-30rem hidden lg:block">
                    <img :src="mediumImage" alt="Image" class="w-full mr-8" />
                  </div>
                  <div class="flex flex-column align-items-center w-2rem">
                    <span
                      class="bg-gray-900 text-0 flex align-items-center justify-content-center border-circle"
                      style="min-width: 2.5rem; min-height: 2.5rem"
                      >3</span
                    >
                    <div class="h-full bg-gray-900" style="width: 2px; min-height: 4rem"></div>
                  </div>
                  <div class="py-3 pl-5 lg:pl-8 pl-3 lg:w-30rem">
                    <div class="font-medium text-2xl mb-3">Everyday Grade</div>
                    <p class="mt-0 mb-4 line-height-3 card-text">
                      Our medium strength Mānuka honey offers a balance of potency and affordability, making it a versatile choice for everyday use.
                      These grades offer a more subtle flavor profile, but still retain the antibacterial and anti-inflammatory properties that make
                      Mānuka honey so special.
                    </p>
                    <img :src="mediumImage" alt="Image" class="w-full mt-3 block lg:hidden" />
                  </div>
                </div>
                <div class="flex justify-content-center mb-5">
                  <div class="py-3 pl-5 pr-3 lg:pr-8 lg:pl-3 lg:w-30rem flex-order-1 lg:flex-order-0">
                    <div class="font-medium text-2xl mb-3">Mānuka Lozenges</div>
                    <p class="mt-0 mb-4 line-height-3 card-text">
                      Our lozenges are a convenient and natural way to alleviate sore throat and cough symptoms. Made with Mānuka honey, they provide
                      the same antibacterial and anti-inflammatory benefits as regular Mānuka honey in a portable form. With their rich flavour, these
                      lozenges are a tasty way to soothe your throat.
                    </p>
                    <img :src="lozengesImage" alt="Image" class="w-full mt-3 block lg:hidden" />
                  </div>
                  <div class="flex flex-column align-items-center w-2rem flex-order-0 lg:flex-order-1">
                    <span
                      class="bg-gray-900 text-0 flex align-items-center justify-content-center border-circle"
                      style="min-width: 2.5rem; min-height: 2.5rem"
                      >4</span
                    >
                    <div class="h-full bg-gray-900" style="width: 2px; min-height: 4rem"></div>
                  </div>
                  <div class="py-3 pl-8 pr-3 w-30rem hidden lg:block flex-order-2">
                    <img :src="lozengesImage" alt="Image" class="w-full mr-8" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="grid grid-no-gutter">
            <div class="col-12 lg:col-8 md:pr-4 mt-6 flex">
              <div class="surface-card shadow-2 border-round p-4 w-full">
                <h2 class="top-padding">UNDERSTANDING MĀNUKA HONEY</h2>
                <div class="font-medium text-2xl mb-3">What is MGO?</div>
                <p class="mt-0 mb-4 line-height-3 card-text">
                  The potency of Mānuka honey is measured by its MGO rating, which indicates the concentration of MGO (methylglyoxal) in the honey.
                  The higher the MGO rating, the more potent the honey is in terms of its antibacterial and anti-inflammatory effects. MGO ratings
                  typically range from around 50 to over 1000, with higher ratings indicating a stronger and more therapeutic honey. This makes Mānuka
                  honey with high MGO ratings particularly prized for its health benefits.
                </p>
                <div class="font-medium text-2xl mb-3">What is UMF?</div>
                <p class="mt-0 mb-4 line-height-3 card-text">
                  Another type of honey grading system is UMF, or Unique Mānuka Factor. It is another measure of the quality and potency of Mānuka
                  honey. Each Mānuka honey product that carries the UMF trademark must test their honey through the Mānuka Factor Honey Association
                  (UMFHA).
                </p>
                <div class="font-medium text-2xl mb-3">How do I know Mānuka is Authentic?</div>
                <p class="mt-0 mb-4 line-height-3 card-text">
                  At Wero, we take the quality of our Mānuka honey seriously. That's why we ensure all of our honey is tested by accredited labs to
                  verify the presence of the chemical markers that make it authentic. We only use MPI-recognised laboratories to test our honey, as
                  these are the only labs that are authorised to perform the required tests. This way, we can guarantee that our honey meets the
                  <a href="https://www.mpi.govt.nz/food-business/honey-bee-products-processing-requirements/manuka-honey-testing/" target="blank"
                    >rigorous standards set by the New Zealand government</a
                  >
                  for Mānuka honey. We believe in providing our customers with the highest quality Mānuka honey possible, and rigorous testing is just
                  one way we ensure that our products meet those standards.
                </p>
              </div>
            </div>
            <div class="col-12 lg:col-4 md:mt-6 pl-2 flex">
              <div class="surface-card shadow-2 border-round p-4 w-full">
                <div class="flex align-items-center justify-content-between">
                  <div class="font-medium text-2xl mb-3">MGO / UMF Conversion</div>
                </div>
                <div class="surface-border border-1 border-round p-3 mb-4">
                  <p class="mt-0 card-text">
                    The UMF rating system is not directly convertible to the New Zealand government's Mānuka honey identification tests. However, we
                    can assign minimum MGO requirements to a UMF rating to provide an estimate of the equivalent UMF rating.
                  </p>
                </div>

                <ul class="mt-4 list-none p-0 mx-0">
                  <li class="flex align-items-center pb-3">
                    <span style="width: 1rem; height: 1rem" class="border-round bg-gray-300 mr-3 flex-shrink-0"></span>
                    <span class="text-xl font-medium text-90">1050 MGO <span class="label-text">(white label)</span></span>
                    <span class="text-600 text-xl font-medium ml-auto">&#8773; 24 UMF</span>
                  </li>
                  <li class="flex align-items-center py-3">
                    <span style="width: 1rem; height: 1rem" class="border-round bg-gray-900 mr-3 flex-shrink-0"></span>
                    <span class="text-xl font-medium text-90">800 MGO <span class="label-text">(black label)</span></span>
                    <span class="text-600 text-xl font-medium ml-auto">&#8773; 20 UMF</span>
                  </li>
                  <li class="flex align-items-center py-3">
                    <span style="width: 1rem; height: 1rem" class="border-round bg-orange-500 mr-3 flex-shrink-0"></span>
                    <span class="text-xl font-medium text-90">600 MGO <span class="label-text">(orange label)</span></span>
                    <span class="text-600 text-xl font-medium ml-auto">&#8773; 16 UMF</span>
                  </li>
                  <li class="flex align-items-center py-3">
                    <span style="width: 1rem; height: 1rem" class="border-round bg-pink-600 mr-3 flex-shrink-0"></span>
                    <span class="text-xl font-medium text-90">500 MGO <span class="label-text">(red label)</span></span>
                    <span class="text-600 text-xl font-medium ml-auto">&#8773; 15 UMF</span>
                  </li>
                  <li class="flex align-items-center py-3">
                    <span style="width: 1rem; height: 1rem" class="border-round bg-green-300 mr-3 flex-shrink-0"></span>
                    <span class="text-xl font-medium text-90">400 MGO <span class="label-text">(green label)</span></span>
                    <span class="text-600 text-xl font-medium ml-auto">&#8773; 12 UMF</span>
                  </li>
                  <li class="flex align-items-center py-3">
                    <span style="width: 1rem; height: 1rem" class="border-round bg-cyan-500 mr-3 flex-shrink-0"></span>
                    <span class="text-xl font-medium text-90">300 MGO <span class="label-text">(blue label)</span></span>
                    <span class="text-600 text-xl font-medium ml-auto">&#8773; 10 UMF</span>
                  </li>
                  <li class="flex align-items-center py-3">
                    <span style="width: 1rem; height: 1rem" class="border-round bg-pink-200 mr-3 flex-shrink-0"></span>
                    <span class="text-xl font-medium text-90">200 MGO <span class="label-text">(pink label)</span></span>
                    <span class="text-600 text-xl font-medium ml-auto">&#8773; 8 UMF</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Why",

  components: {},

  mounted() {},

  data() {
    return {
      whyManukaBanner: require("../assets/images/assets/why-manuka-banner.png"),
      placeholderImage: require("../assets/images/placeholderBanner.jpg"),
      conversionChart: require("../assets/images/conversion chart.jpg"),
      wholesaleImage: require("../assets/images/wholesale-honey.jpg"),
      ultraHighImage: require("../assets/images/assets/ultra-high-grade.png"),
      highImage: require("../assets/images/assets/high-grade.png"),
      mediumImage: require("../assets/images/assets/everyday-grade.png"),
      lozengesImage: require("../assets/images/assets/lozenges.png"),
      skincareIcon: require("../assets/images/assets/skincare.png"),
      woundcareIcon: require("../assets/images/assets/woundcare.png"),
      immunityIcon: require("../assets/images/assets/immunity.png"),
    };
  },

  methods: {
    routeToContact: function () {
      // Change to about page - and scroll to top !
      this.$router.push({ name: "Contact" });
      window.scrollTo(0, 0);
    },
  },

  computed: {
    vuex: function () {
      return this.$store.state;
    },
  },

  watch: {},
};
</script>

<style scoped>
.conversion-image {
  max-width: 100%;
}

.card-text {
  font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
  font-size: 1rem;
  font-weight: light;
}

.label-text {
  font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
  font-size: 0.8rem;
  font-weight: normal;
  font-style: italic;
}

.honey-spoon {
  background-position: center;
  background-size: cover;
}

@media only screen and (max-width: 767px) {
  .justify-mobile-only {
    text-align: justify;
  }
}

@media only screen and (min-width: 768px) {
  .justify-mobile-only {
    text-align: left;
  }
}
.fullwidth {
  width: 100%;
}

.gap-5 {
  gap: 2rem !important;
}
.wrapper {
  width: 100%;
}
</style>
