<template>
  <div class="grid grid-nogutter justify-content-center bottom-fix">
        <div class="col-12 md:col-4 center-text" data-aos="fade-right" data-aos-once="true">
          <div class="caption-container">
            <img :src="dubaiHoney" class="world-image" alt="Rolling sand dunes of Umm Al Quwain Dubai" />
            <p v-if="1" class="informational-text caption">Rolling sand dunes of Umm Al Quwain Dubai</p>
          </div>

        </div>

      <div class="col-12 md:col-4 center-text" data-aos="fade-left" data-aos-once="true">
        <div class="caption-container">
          <img :src="testImage" class="world-image" alt="Right here in NZ" />
          <p v-if="1" class="informational-text caption caption">Right here in Aotearoa, New Zealand</p>
        </div>
      </div>

        <div class="col-12 md:col-4 center-text" data-aos="fade-left" data-aos-once="true">
          <div class="caption-container">
            <img :src="swissHoney" class="world-image" alt="Beautiful mountains of Switzerland" />
            <p v-if="1" class="informational-text caption dark-caption">Beautiful mountains of Switzerland</p>
          </div>

        </div>
  </div>

</template>

<script>
export default {
  name: 'WorldImages',

  props: {

  },

  mounted() {

  },

  data() {
    return {
      dubaiHoney: require("../../assets/images/dubai-honey.jpg"),
      swissHoney: require("../../assets/images/switzerland-honey.jpg"),
      testImage: require("../../assets/images/test_01.jpg"),
    }
  },

  methods: {


  },

  computed: {
    vuex: function(){
      return(this.$store.state);
    },

  },

  watch: {

  }

}

</script>


<style scoped>

.world-image{
  width: 100%;
}

.caption-container{
  position: relative;
  text-align: center;
}

.caption{
  position: absolute;
  bottom: 5%;
  width: 100%;
  color: whitesmoke;
  font-size: 1.1rem;
  text-shadow: rgb(0 0 0 / 83%) 5px 0px 5px;
}

.dark-caption{
  color: black;
  text-shadow: rgb(255 255 255 / 83%) 5px 0px 5px;
}

.bottom-fix{
  /* Why does flex do this...*/
  margin-bottom: -5px;
  background-color: white;
}

.right-border{
  border-right: 2px solid black;
}

.left-border{
  border-left: 2px solid black;
}


</style>


