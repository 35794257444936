<template>
  <div class="grid" style="align-items: stretch">
    <!-- White Jar -->
    <div v-if="oldversion" class="col-12 md:col-6 lg:col-3 product-card-wrapper" data-aos="zoom-in" data-aos-once="true">
      <Card class="product-card">
        <template #header>
          <div class="header-wrapper">
            <img :src="mgo1050Image" class="product-image" />
          </div>
        </template>
        <template #title>
          <div class="center-text card-title">
            <span class="block">Mā</span>
          </div>
        </template>
        <template #subtitle>
          <div class="center-text card-subtitle">
            White Label<br /><br />
            1050+ MGO<br />
            24+ UMF
          </div>
        </template>
        <template #content>
          <div v-if="buyActive" class="buy-button" id="product-component-1680833655999" style="max-width: 100%; width: 100%"></div>
          <p class="honey-card-text">
            Our rarest and our most prized honey, Mā is rated at 1050+ MGO. This Mānuka blend is the most luxurious and coveted of our range. Indulge
            in the lavish quality and taste of authentic New Zealand honey with unmistakable health benefits.
          </p>
        </template>
      </Card>
    </div>
    <!-- White Jar new -->
    <div v-if="!oldversion" class="col-12 md:col-6 lg:col-3 product-card-wrapper" data-aos="zoom-in" data-aos-once="true">
      <Card class="product-card">
        <template #header>
          <div class="header-wrapper">
            <img :src="mgo1050Image" class="product-image" />
          </div>
        </template>
        <template #title>
          <div class="flex flex-column align-items-center mb-3">
            <span class="font-medium text-900 text-2xl mb-1">Mā</span>
            <div class="center-text text-base card-subtitle">(White Label)</div>
          </div>
        </template>
        <template #subtitle>
          <div class="flex flex-column align-items-center pb-4 border-bottom-1 surface-border">
            <span class="text-600 surface-200 p-2 border-round">1050+ MGO</span>
          </div>
        </template>
        <template #content>
          <p class="honey-card-text">
            Our rarest and most prized honey, with an intense and distinct flavor. Its high MGO rating of 1050+ makes it the ideal choice for those
            seeking the strongest medicinal benefits.
          </p>
          <div class="pt-3 border-top-1 surface-border flex">
            <div v-if="buyActive" class="buy-button" id="product-component-1680833655999" style="max-width: 100%; width: 100%"></div>
          </div>
        </template>
      </Card>
    </div>

    <!-- Black Jar -->
    <div v-if="oldversion" class="col-12 md:col-6 lg:col-3 product-card-wrapper" data-aos="zoom-in" data-aos-once="true">
      <Card class="product-card">
        <template #header>
          <div class="header-wrapper">
            <img :src="mgo800Image" class="product-image" />
          </div>
        </template>
        <template #title>
          <div class="center-text card-title">
            <span class="block">Pango</span>
          </div>
        </template>
        <template #subtitle>
          <div class="center-text card-subtitle">
            Black Label<br /><br />
            800+ MGO<br />
            20+ UMF
          </div>
        </template>
        <template #content>
          <div v-if="buyActive" class="buy-button" id="product-component-1680833679309" style="max-width: 100%; width: 100%"></div>
          <p class="honey-card-text">
            With MGO content of 800+, only a very small taste of Pango is needed to claim the benefits of New Zealand's Mānuka honey. Soft, sweet and
            rich in flavour, this honey is a treat to savour.
          </p>
        </template>
      </Card>
    </div>

    <!-- Black Jar new -->
    <div v-if="!oldversion" class="col-12 md:col-6 lg:col-3 product-card-wrapper" data-aos="zoom-in" data-aos-once="true">
      <Card class="product-card">
        <template #header>
          <div class="header-wrapper">
            <img :src="mgo800Image" class="product-image" />
          </div>
        </template>
        <template #title>
          <div class="flex flex-column align-items-center mb-3">
            <span class="font-medium text-900 text-2xl mb-1">Pango</span>
            <div class="center-text text-base card-subtitle">(Black Label)</div>
          </div>
        </template>
        <template #subtitle>
          <div class="flex flex-column align-items-center pb-4 border-bottom-1 surface-border">
            <span class="text-600 surface-200 p-2 border-round">800+ MGO</span>
          </div>
        </template>
        <template #content>
          <p class="honey-card-text">
            Pango is a soft, sweet, and rich honey that requires only small quantities to reap its benefits. Enjoy it straight off the spoon for a
            boost to your overall health and wellbeing.
          </p>
          <div class="pt-3 border-top-1 surface-border flex">
            <div v-if="buyActive" class="buy-button" id="product-component-1680833679309" style="max-width: 100%; width: 100%"></div>
          </div>
        </template>
      </Card>
    </div>

    <!-- Orange Jar -->
    <div v-if="oldversion" class="col-12 md:col-6 lg:col-3 product-card-wrapper" data-aos="zoom-in" data-aos-once="true">
      <Card class="product-card">
        <template #header>
          <div class="header-wrapper">
            <img :src="mgo600Image" class="product-image" />
          </div>
        </template>
        <template #title>
          <div class="center-text card-title">
            <span class="block">Karaka</span>
          </div>
        </template>
        <template #subtitle>
          <div class="center-text card-subtitle">
            Orange Label<br /><br />
            600+ MGO<br />
            16+ UMF
          </div>
        </template>
        <template #content>
          <div v-if="buyActive" class="buy-button" id="product-component-1680833921722" style="max-width: 100%; width: 100%"></div>
          <p class="honey-card-text">
            At 600+ MGO, Karaka honey is a great option for experiencing the medicinal effects of Mānuka honey. Luxurious and velvety, this honey
            effortlessly drips into teas or is easy to enjoy straight off your spoon.
          </p>
        </template>
      </Card>
    </div>

    <!-- Orange Jar new -->
    <div v-if="!oldversion" class="col-12 md:col-6 lg:col-3 product-card-wrapper" data-aos="zoom-in" data-aos-once="true">
      <Card class="product-card">
        <template #header>
          <div class="header-wrapper">
            <img :src="mgo600Image" class="product-image" />
          </div>
        </template>
        <template #title>
          <div class="flex flex-column align-items-center mb-3">
            <span class="font-medium text-900 text-2xl mb-1">Karaka</span>
            <div class="center-text text-base card-subtitle">(Orange Label)</div>
          </div>
        </template>
        <template #subtitle>
          <div class="flex flex-column align-items-center pb-4 border-bottom-1 surface-border">
            <span class="text-600 surface-200 p-2 border-round">600+ MGO</span>
          </div>
        </template>
        <template #content>
          <p class="honey-card-text">
            Luxurious and velvety, Karaka honey boasts an MGO rating of 600+. This honey is a great choice for experiencing the therapeutic effects of
            Mānuka honey.
          </p>
          <div class="pt-3 border-top-1 surface-border flex">
            <div v-if="buyActive" class="buy-button" id="product-component-1680833921722" style="max-width: 100%; width: 100%"></div>
          </div>
        </template>
      </Card>
    </div>

    <!-- Red Jar -->
    <div v-if="oldversion" class="col-12 md:col-6 lg:col-3 product-card-wrapper" data-aos="zoom-in" data-aos-once="true">
      <Card class="product-card">
        <template #header>
          <div class="header-wrapper">
            <img :src="mgo500Image" class="product-image" />
          </div>
        </template>
        <template #title>
          <div class="center-text card-title">
            <span class="block">Whero</span>
          </div>
        </template>
        <template #subtitle>
          <div class="center-text card-subtitle">
            Red Label<br /><br />
            500+ MGO<br />
            15+ UMF
          </div>
        </template>
        <template #content>
          <div v-if="buyActive" class="buy-button" id="product-component-1680846171144" style="max-width: 100%; width: 100%"></div>
          <p class="honey-card-text">
            Our MGO honey Whero 500+ is concentrated for your use. Just a small spoonful daily can bring you positive health benefits with that
            quality New Zealand honey taste.
          </p>
        </template>
      </Card>
    </div>

    <!-- Red Jar new -->
    <div v-if="!oldversion" class="col-12 md:col-6 lg:col-3 product-card-wrapper" data-aos="zoom-in" data-aos-once="true">
      <Card class="product-card">
        <template #header>
          <div class="header-wrapper">
            <img :src="mgo500Image" class="product-image" />
          </div>
        </template>
        <template #title>
          <div class="flex flex-column align-items-center mb-3">
            <span class="font-medium text-900 text-2xl mb-1">Whero</span>
            <div class="center-text text-base card-subtitle">(Red Label)</div>
          </div>
        </template>
        <template #subtitle>
          <div class="flex flex-column align-items-center pb-4 border-bottom-1 surface-border">
            <span class="text-600 surface-200 p-2 border-round">500+ MGO</span>
          </div>
        </template>
        <template #content>
          <p class="honey-card-text">
            A great mid-range option for those seeking the benefits of authentic Mānuka honey. Its distinct Mānuka taste is perfect for those who
            enjoy a bold, natural flavor.
          </p>
          <div class="pt-3 border-top-1 surface-border flex">
            <div v-if="buyActive" class="buy-button" id="product-component-1680846171144" style="max-width: 100%; width: 100%"></div>
          </div>
        </template>
      </Card>
    </div>

    <!-- Green Jar -->
    <div v-if="oldversion" class="col-12 md:col-6 lg:col-3 product-card-wrapper" data-aos="zoom-in" data-aos-once="true">
      <Card class="product-card">
        <template #header>
          <div class="header-wrapper">
            <img :src="mgo400Image" class="product-image" />
          </div>
        </template>
        <template #title>
          <div class="center-text card-title">
            <span class="block">Kākāriki</span>
          </div>
        </template>
        <template #subtitle>
          <div class="center-text card-subtitle">
            Green Label<br /><br />
            400+ MGO<br />
            12+ UMF
          </div>
        </template>
        <template #content>
          <div v-if="buyActive" class="buy-button" id="product-component-1680848013109" style="max-width: 100%; width: 100%"></div>
          <p class="honey-card-text">
            At 400+ MGO, you can really taste the classic true flavour of New Zealand Mānuka honey in Kākāriki. The remarkable properties are sure to
            help you start your day with an extra spring in your step.
          </p>
        </template>
      </Card>
    </div>

    <!-- Green Jar new -->
    <div v-if="!oldversion" class="col-12 md:col-6 lg:col-3 product-card-wrapper" data-aos="zoom-in" data-aos-once="true">
      <Card class="product-card">
        <template #header>
          <div class="header-wrapper">
            <img :src="mgo400Image" class="product-image" />
          </div>
        </template>
        <template #title>
          <div class="flex flex-column align-items-center mb-3">
            <span class="font-medium text-900 text-2xl mb-1">Kākāriki</span>
            <div class="center-text text-base card-subtitle">Green Label</div>
          </div>
        </template>
        <template #subtitle>
          <div class="flex flex-column align-items-center pb-4 border-bottom-1 surface-border">
            <span class="text-600 surface-200 p-2 border-round">400+ MGO</span>
          </div>
        </template>
        <template #content>
          <p class="honey-card-text">
            With an MGO rating of 400+, Kākāriki honey has a classic and authentic Mānuka taste. This honey is perfect for those who want to start
            their day with a little extra energy and vitality.
          </p>
          <div class="pt-3 border-top-1 surface-border flex">
            <div v-if="buyActive" class="buy-button" id="product-component-1680848013109" style="max-width: 100%; width: 100%"></div>
          </div>
        </template>
      </Card>
    </div>

    <!-- Blue Jar -->
    <div v-if="oldversion" class="col-12 md:col-6 lg:col-3 product-card-wrapper" data-aos="zoom-in" data-aos-once="true">
      <Card class="product-card">
        <template #header>
          <div class="header-wrapper">
            <img :src="mgo300Image" class="product-image" />
          </div>
        </template>
        <template #title>
          <div class="center-text card-title">
            <span class="block">Kikorangi</span>
          </div>
        </template>
        <template #subtitle>
          <div class="center-text card-subtitle">
            Blue Label<br /><br />
            300+ MGO<br />
            10+ UMF
          </div>
        </template>
        <template #content>
          <div v-if="buyActive" class="buy-button" id="product-component-1680848087963" style="max-width: 100%; width: 100%"></div>
          <p class="honey-card-text">
            Our 300+ MGO Kikorangi honey is great value for people wanting to dip their toes into the world of superfood honey without compromising on
            quality, taste or health benefits.
          </p>
        </template>
      </Card>
    </div>

    <!-- Blue Jar new -->
    <div v-if="!oldversion" class="col-12 md:col-6 lg:col-3 product-card-wrapper" data-aos="zoom-in" data-aos-once="true">
      <Card class="product-card">
        <template #header>
          <div class="header-wrapper">
            <img :src="mgo300Image" class="product-image" />
          </div>
        </template>
        <template #title>
          <div class="flex flex-column align-items-center mb-3">
            <span class="font-medium text-900 text-2xl mb-2">Kikorangi</span>
            <div class="center-text text-base card-subtitle">(Blue Label)</div>
          </div>
        </template>
        <template #subtitle>
          <div class="flex flex-column align-items-center pb-4 border-bottom-1 surface-border">
            <span class="text-600 surface-200 p-2 border-round">300+ MGO</span>
          </div>
        </template>
        <template #content>
          <p class="honey-card-text">
            Kikorangi honey, with an MGO rating of 300+, is a perfect for those who are starting to explore the world of Mānuka honey or for those who
            prefer a more subtle taste. A great value option.
          </p>
          <div class="pt-3 border-top-1 surface-border flex">
            <div v-if="buyActive" class="buy-button" id="product-component-1680848087963" style="max-width: 100%; width: 100%"></div>
          </div>
        </template>
      </Card>
    </div>

    <!-- Pink Jar -->
    <div v-if="oldversion" class="col-12 md:col-6 lg:col-3 product-card-wrapper" data-aos="zoom-in" data-aos-once="true">
      <Card class="product-card">
        <template #header>
          <div class="header-wrapper">
            <img :src="mgo200Image" class="product-image" />
          </div>
        </template>
        <template #title>
          <div class="center-text card-title">
            <span class="block">Māwhero</span>
          </div>
        </template>
        <template #subtitle>
          <div class="center-text card-subtitle">
            Pink Label<br /><br />
            200+ MGO<br />
            8+ UMF
          </div>
        </template>
        <template #content>
          <div v-if="buyActive" class="buy-button" id="product-component-1680848235508" style="max-width: 100%; width: 100%"></div>
          <p class="honey-card-text">
            Our ataahua (beautiful) 200+ MGO honey is our newest and most affordable product on the market. Great for coughs and colds or to sweeten
            your favourite winter beverage or summer smoothie.
          </p>
        </template>
      </Card>
    </div>

    <!-- Pink Jar new -->
    <div v-if="!oldversion" class="col-12 md:col-6 lg:col-3 product-card-wrapper" data-aos="zoom-in" data-aos-once="true">
      <Card class="product-card">
        <template #header>
          <div class="header-wrapper">
            <img :src="mgo200Image" class="product-image" />
          </div>
        </template>
        <template #title>
          <div class="flex flex-column align-items-center mb-3">
            <span class="font-medium text-900 text-2xl mb-2">Māwhero</span>
            <div class="center-text text-base card-subtitle">(Pink Label)</div>
          </div>
        </template>
        <template #subtitle>
          <div class="flex flex-column align-items-center pb-4 border-bottom-1 surface-border">
            <span class="text-600 surface-200 p-2 border-round">200+ MGO</span>
          </div>
        </template>
        <template #content>
          <p class="honey-card-text">
            Māwhero honey is our newest and most affordable product. Ideal for those new to Mānuka honey and seeking a budget-friendly option to
            support their overall health and wellness.
          </p>
          <div class="pt-3 border-top-1 surface-border flex">
            <div v-if="buyActive" class="buy-button" id="product-component-1680848235508" style="max-width: 100%; width: 100%"></div>
          </div>
        </template>
      </Card>
    </div>

    <!-- Lozenges -->
    <div v-if="oldversion" class="col-12 md:col-6 lg:col-3 product-card-wrapper" data-aos="zoom-in" data-aos-once="true">
      <Card class="product-card">
        <template #header>
          <div class="header-wrapper">
            <img :src="losengesImage" class="product-image" />
          </div>
        </template>
        <template #title>
          <div class="center-text card-title">
            <span class="block">Lozenges</span>
          </div>
        </template>
        <template #subtitle>
          <div class="center-text card-subtitle">
            Gold Label<br /><br />
            400+ MGO<br />
            12+ UMF
          </div>
        </template>
        <template #content>
          <div v-if="buyActive" class="buy-button" id="product-component-1680848284701" style="max-width: 100%; width: 100%"></div>
          <p class="honey-card-text">
            Our pure Mānuka honey lozenges provide all of the same qualities as our 400+ Kākāriki honey with the extra benefit of easily taking it to
            the office or popping in your bag on a day out.
          </p>
        </template>
      </Card>
    </div>

    <!-- Lozenges new -->
    <div v-if="!oldversion" class="col-12 md:col-6 lg:col-3 product-card-wrapper" data-aos="zoom-in" data-aos-once="true">
      <Card class="product-card">
        <template #header>
          <div class="header-wrapper">
            <img :src="losengesImage" class="product-image" />
          </div>
        </template>
        <template #title>
          <div class="flex flex-column align-items-center mb-3">
            <span class="font-medium text-900 text-2xl mb-2">Lozenges</span>
            <div class="center-text text-base card-subtitle">(Gold Label)</div>
          </div>
        </template>
        <template #subtitle>
          <div class="flex flex-column align-items-center pb-4 border-bottom-1 surface-border">
            <span class="text-600 surface-200 p-2 border-round">400+ MGO</span>
          </div>
        </template>
        <template #content>
          <p class="honey-card-text">
            Experience the same natural and soothing benefits of our 400+ MGO Kākāriki honey in a convenient and portable form. Perfect for on-the-go
            relief of sore throats and coughs.
          </p>
          <div class="pt-3 border-top-1 surface-border flex">
            <div v-if="buyActive" class="buy-button" id="product-component-1680848284701" style="max-width: 100%; width: 100%"></div>
          </div>
        </template>
      </Card>
    </div>

    <div class="warning-wrapper center-text top-padding bottom-padding">
      <p class="informational-text">*Mānuka honey products are not suitable for children under 12 months old</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardSection",

  props: {},

  mounted() {
    if (this.buyActive) {
      let shopifyBuy = document.createElement("script");

      shopifyBuy.innerText =
        "/*<![CDATA[*/\n" +
        "(function () {\n" +
        "  var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';\n" +
        "  if (window.ShopifyBuy) {\n" +
        "    if (window.ShopifyBuy.UI) {\n" +
        "      ShopifyBuyInit();\n" +
        "    } else {\n" +
        "      loadScript();\n" +
        "    }\n" +
        "  } else {\n" +
        "    loadScript();\n" +
        "  }\n" +
        "  function loadScript() {\n" +
        "    var script = document.createElement('script');\n" +
        "    script.async = true;\n" +
        "    script.src = scriptURL;\n" +
        "    (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);\n" +
        "    script.onload = ShopifyBuyInit;\n" +
        "  }\n" +
        "  function ShopifyBuyInit() {\n" +
        "    var client = ShopifyBuy.buildClient({\n" +
        "      domain: '1338d8.myshopify.com',\n" +
        "      storefrontAccessToken: 'c63dcbc27baaef08d2e5848f804d18a6',\n" +
        "    });\n" +
        "    ShopifyBuy.UI.onReady(client).then(function (ui) {" +
        "      ui.createComponent('product', {\n" +
        "        id: '8229253382439',\n" +
        "        node: document.getElementById('product-component-1680833655999'),\n" +
        "        moneyFormat: '%24%7B%7Bamount%7D%7D',\n" +
        "        options: {\n" +
        '  "product": {\n' +
        '    "styles": {\n' +
        '      "product": {\n' +
        '        "@media (min-width: 601px)": {\n' +
        '          "max-width": "calc(25% - 20px)",\n' +
        '          "margin-left": "20px",\n' +
        '          "margin-bottom": "50px"\n' +
        "        }\n" +
        "      },\n" +
        '      "button": {\n' +
        '        "font-weight": "bold",\n' +
        '        ":hover": {\n' +
        '          "background-color": "#000000"\n' +
        "        },\n" +
        '        "background-color": "#000000",\n' +
        '        ":focus": {\n' +
        '          "background-color": "#000000"\n' +
        "        }\n" +
        "      }\n" +
        "    },\n" +
        '    "contents": {\n' +
        '      "img": false,\n' +
        '      "title": false,\n' +
        '      "price": false\n' +
        "    },\n" +
        '    "text": {\n' +
        '      "button": "Add to cart"\n' +
        "    }\n" +
        "  },\n" +
        '  "productSet": {\n' +
        '    "styles": {\n' +
        '      "products": {\n' +
        '        "@media (min-width: 601px)": {\n' +
        '          "margin-left": "-20px"\n' +
        "        }\n" +
        "      }\n" +
        "    }\n" +
        "  },\n" +
        '  "modalProduct": {\n' +
        '    "contents": {\n' +
        '      "img": false,\n' +
        '      "imgWithCarousel": true,\n' +
        '      "button": false,\n' +
        '      "buttonWithQuantity": true\n' +
        "    },\n" +
        '    "styles": {\n' +
        '      "product": {\n' +
        '        "@media (min-width: 601px)": {\n' +
        '          "max-width": "100%",\n' +
        '          "margin-left": "0px",\n' +
        '          "margin-bottom": "0px"\n' +
        "        }\n" +
        "      },\n" +
        '      "button": {\n' +
        '        "font-weight": "bold",\n' +
        '        ":hover": {\n' +
        '          "background-color": "#000000"\n' +
        "        },\n" +
        '        "background-color": "#000000",\n' +
        '        ":focus": {\n' +
        '          "background-color": "#000000"\n' +
        "        }\n" +
        "      }\n" +
        "    },\n" +
        '    "text": {\n' +
        '      "button": "Add to cart"\n' +
        "    }\n" +
        "  },\n" +
        '  "option": {},\n' +
        '  "cart": {\n' +
        '    "styles": {\n' +
        '      "button": {\n' +
        '        "font-weight": "bold",\n' +
        '        ":hover": {\n' +
        '          "background-color": "#000000"\n' +
        "        },\n" +
        '        "background-color": "#000000",\n' +
        '        ":focus": {\n' +
        '          "background-color": "#000000"\n' +
        "        }\n" +
        "      }\n" +
        "    },\n" +
        '    "text": {\n' +
        '      "total": "Subtotal",\n' +
        '      "button": "Checkout"\n' +
        "    },\n" +
        '    "contents": {\n' +
        '      "note": true\n' +
        "    }\n" +
        "  },\n" +
        '  "toggle": {\n' +
        '    "styles": {\n' +
        '      "toggle": {\n' +
        '        "font-weight": "bold",\n' +
        '        "background-color": "#000000",\n' +
        '        ":hover": {\n' +
        '          "background-color": "#000000"\n' +
        "        },\n" +
        '        ":focus": {\n' +
        '          "background-color": "#000000"\n' +
        "        }\n" +
        "      }\n" +
        "    }\n" +
        "  }\n" +
        "},\n" +
        "      });\n" +
        "\t  \n" +
        "\t  ui.createComponent('product', {\n" +
        "        id: '8229432197415',\n" +
        "        node: document.getElementById('product-component-1680833679309'),\n" +
        "        moneyFormat: '%24%7B%7Bamount%7D%7D',\n" +
        "        options: {\n" +
        '  "product": {\n' +
        '    "styles": {\n' +
        '      "product": {\n' +
        '        "@media (min-width: 601px)": {\n' +
        '          "max-width": "calc(25% - 20px)",\n' +
        '          "margin-left": "20px",\n' +
        '          "margin-bottom": "50px"\n' +
        "        }\n" +
        "      },\n" +
        '      "button": {\n' +
        '        "font-weight": "bold",\n' +
        '        ":hover": {\n' +
        '          "background-color": "#000000"\n' +
        "        },\n" +
        '        "background-color": "#000000",\n' +
        '        ":focus": {\n' +
        '          "background-color": "#000000"\n' +
        "        }\n" +
        "      }\n" +
        "    },\n" +
        '    "contents": {\n' +
        '      "img": false,\n' +
        '      "title": false,\n' +
        '      "price": false\n' +
        "    },\n" +
        '    "text": {\n' +
        '      "button": "Add to cart"\n' +
        "    }\n" +
        "  },\n" +
        '  "productSet": {\n' +
        '    "styles": {\n' +
        '      "products": {\n' +
        '        "@media (min-width: 601px)": {\n' +
        '          "margin-left": "-20px"\n' +
        "        }\n" +
        "      }\n" +
        "    }\n" +
        "  },\n" +
        '  "modalProduct": {\n' +
        '    "contents": {\n' +
        '      "img": false,\n' +
        '      "imgWithCarousel": true,\n' +
        '      "button": false,\n' +
        '      "buttonWithQuantity": true\n' +
        "    },\n" +
        '    "styles": {\n' +
        '      "product": {\n' +
        '        "@media (min-width: 601px)": {\n' +
        '          "max-width": "100%",\n' +
        '          "margin-left": "0px",\n' +
        '          "margin-bottom": "0px"\n' +
        "        }\n" +
        "      },\n" +
        '      "button": {\n' +
        '        "font-weight": "bold",\n' +
        '        ":hover": {\n' +
        '          "background-color": "#000000"\n' +
        "        },\n" +
        '        "background-color": "#000000",\n' +
        '        ":focus": {\n' +
        '          "background-color": "#000000"\n' +
        "        }\n" +
        "      }\n" +
        "    },\n" +
        '    "text": {\n' +
        '      "button": "Add to cart"\n' +
        "    }\n" +
        "  },\n" +
        '  "option": {},\n' +
        '  "cart": {\n' +
        '    "styles": {\n' +
        '      "button": {\n' +
        '        "font-weight": "bold",\n' +
        '        ":hover": {\n' +
        '          "background-color": "#000000"\n' +
        "        },\n" +
        '        "background-color": "#000000",\n' +
        '        ":focus": {\n' +
        '          "background-color": "#000000"\n' +
        "        }\n" +
        "      }\n" +
        "    },\n" +
        '    "text": {\n' +
        '      "total": "Subtotal",\n' +
        '      "button": "Checkout"\n' +
        "    },\n" +
        '    "contents": {\n' +
        '      "note": true\n' +
        "    }\n" +
        "  },\n" +
        '  "toggle": {\n' +
        '    "styles": {\n' +
        '      "toggle": {\n' +
        '        "font-weight": "bold",\n' +
        '        "background-color": "#000000",\n' +
        '        ":hover": {\n' +
        '          "background-color": "#000000"\n' +
        "        },\n" +
        '        ":focus": {\n' +
        '          "background-color": "#000000"\n' +
        "        }\n" +
        "      }\n" +
        "    }\n" +
        "  }\n" +
        "},\n" +
        "      });" +
        "\t  ui.createComponent('product', {\n" +
        "        id: '8229433311527',\n" +
        "        node: document.getElementById('product-component-1680833921722'),\n" +
        "        moneyFormat: '%24%7B%7Bamount%7D%7D',\n" +
        "        options: {\n" +
        '  "product": {\n' +
        '    "styles": {\n' +
        '      "product": {\n' +
        '        "@media (min-width: 601px)": {\n' +
        '          "max-width": "calc(25% - 20px)",\n' +
        '          "margin-left": "20px",\n' +
        '          "margin-bottom": "50px"\n' +
        "        }\n" +
        "      },\n" +
        '      "button": {\n' +
        '        "font-weight": "bold",\n' +
        '        ":hover": {\n' +
        '          "background-color": "#000000"\n' +
        "        },\n" +
        '        "background-color": "#000000",\n' +
        '        ":focus": {\n' +
        '          "background-color": "#000000"\n' +
        "        }\n" +
        "      }\n" +
        "    },\n" +
        '    "contents": {\n' +
        '      "img": false,\n' +
        '      "title": false,\n' +
        '      "price": false\n' +
        "    },\n" +
        '    "text": {\n' +
        '      "button": "Add to cart"\n' +
        "    }\n" +
        "  },\n" +
        '  "productSet": {\n' +
        '    "styles": {\n' +
        '      "products": {\n' +
        '        "@media (min-width: 601px)": {\n' +
        '          "margin-left": "-20px"\n' +
        "        }\n" +
        "      }\n" +
        "    }\n" +
        "  },\n" +
        '  "modalProduct": {\n' +
        '    "contents": {\n' +
        '      "img": false,\n' +
        '      "imgWithCarousel": true,\n' +
        '      "button": false,\n' +
        '      "buttonWithQuantity": true\n' +
        "    },\n" +
        '    "styles": {\n' +
        '      "product": {\n' +
        '        "@media (min-width: 601px)": {\n' +
        '          "max-width": "100%",\n' +
        '          "margin-left": "0px",\n' +
        '          "margin-bottom": "0px"\n' +
        "        }\n" +
        "      },\n" +
        '      "button": {\n' +
        '        "font-weight": "bold",\n' +
        '        ":hover": {\n' +
        '          "background-color": "#000000"\n' +
        "        },\n" +
        '        "background-color": "#000000",\n' +
        '        ":focus": {\n' +
        '          "background-color": "#000000"\n' +
        "        }\n" +
        "      }\n" +
        "    },\n" +
        '    "text": {\n' +
        '      "button": "Add to cart"\n' +
        "    }\n" +
        "  },\n" +
        '  "option": {},\n' +
        '  "cart": {\n' +
        '    "styles": {\n' +
        '      "button": {\n' +
        '        "font-weight": "bold",\n' +
        '        ":hover": {\n' +
        '          "background-color": "#000000"\n' +
        "        },\n" +
        '        "background-color": "#000000",\n' +
        '        ":focus": {\n' +
        '          "background-color": "#000000"\n' +
        "        }\n" +
        "      }\n" +
        "    },\n" +
        '    "text": {\n' +
        '      "total": "Subtotal",\n' +
        '      "button": "Checkout"\n' +
        "    },\n" +
        '    "contents": {\n' +
        '      "note": true\n' +
        "    }\n" +
        "  },\n" +
        '  "toggle": {\n' +
        '    "styles": {\n' +
        '      "toggle": {\n' +
        '        "font-weight": "bold",\n' +
        '        "background-color": "#000000",\n' +
        '        ":hover": {\n' +
        '          "background-color": "#000000"\n' +
        "        },\n" +
        '        ":focus": {\n' +
        '          "background-color": "#000000"\n' +
        "        }\n" +
        "      }\n" +
        "    }\n" +
        "  }\n" +
        "},\n" +
        "      });\n" +
        "\t  \n" +
        "      ui.createComponent('product', {\n" +
        "        id: '8229433934119',\n" +
        "        node: document.getElementById('product-component-1680846171144'),\n" +
        "        moneyFormat: '%24%7B%7Bamount%7D%7D',\n" +
        "        options: {\n" +
        '  "product": {\n' +
        '    "styles": {\n' +
        '      "product": {\n' +
        '        "@media (min-width: 601px)": {\n' +
        '          "max-width": "calc(25% - 20px)",\n' +
        '          "margin-left": "20px",\n' +
        '          "margin-bottom": "50px"\n' +
        "        }\n" +
        "      },\n" +
        '      "button": {\n' +
        '        "font-weight": "bold",\n' +
        '        ":hover": {\n' +
        '          "background-color": "#000000"\n' +
        "        },\n" +
        '        "background-color": "#000000",\n' +
        '        ":focus": {\n' +
        '          "background-color": "#000000"\n' +
        "        }\n" +
        "      }\n" +
        "    },\n" +
        '    "contents": {\n' +
        '      "img": false,\n' +
        '      "title": false,\n' +
        '      "price": false\n' +
        "    },\n" +
        '    "text": {\n' +
        '      "button": "Add to cart"\n' +
        "    }\n" +
        "  },\n" +
        '  "productSet": {\n' +
        '    "styles": {\n' +
        '      "products": {\n' +
        '        "@media (min-width: 601px)": {\n' +
        '          "margin-left": "-20px"\n' +
        "        }\n" +
        "      }\n" +
        "    }\n" +
        "  },\n" +
        '  "modalProduct": {\n' +
        '    "contents": {\n' +
        '      "img": false,\n' +
        '      "imgWithCarousel": true,\n' +
        '      "button": false,\n' +
        '      "buttonWithQuantity": true\n' +
        "    },\n" +
        '    "styles": {\n' +
        '      "product": {\n' +
        '        "@media (min-width: 601px)": {\n' +
        '          "max-width": "100%",\n' +
        '          "margin-left": "0px",\n' +
        '          "margin-bottom": "0px"\n' +
        "        }\n" +
        "      },\n" +
        '      "button": {\n' +
        '        "font-weight": "bold",\n' +
        '        ":hover": {\n' +
        '          "background-color": "#000000"\n' +
        "        },\n" +
        '        "background-color": "#000000",\n' +
        '        ":focus": {\n' +
        '          "background-color": "#000000"\n' +
        "        }\n" +
        "      }\n" +
        "    },\n" +
        '    "text": {\n' +
        '      "button": "Add to cart"\n' +
        "    }\n" +
        "  },\n" +
        '  "option": {},\n' +
        '  "cart": {\n' +
        '    "styles": {\n' +
        '      "button": {\n' +
        '        "font-weight": "bold",\n' +
        '        ":hover": {\n' +
        '          "background-color": "#000000"\n' +
        "        },\n" +
        '        "background-color": "#000000",\n' +
        '        ":focus": {\n' +
        '          "background-color": "#000000"\n' +
        "        }\n" +
        "      }\n" +
        "    },\n" +
        '    "text": {\n' +
        '      "total": "Subtotal",\n' +
        '      "button": "Checkout"\n' +
        "    },\n" +
        '    "contents": {\n' +
        '      "note": true\n' +
        "    }\n" +
        "  },\n" +
        '  "toggle": {\n' +
        '    "styles": {\n' +
        '      "toggle": {\n' +
        '        "font-weight": "bold",\n' +
        '        "background-color": "#000000",\n' +
        '        ":hover": {\n' +
        '          "background-color": "#000000"\n' +
        "        },\n" +
        '        ":focus": {\n' +
        '          "background-color": "#000000"\n' +
        "        }\n" +
        "      }\n" +
        "    }\n" +
        "  }\n" +
        "},\n" +
        "      });" +
        "      ui.createComponent('product', {\n" +
        "        id: '8229435146535',\n" +
        "        node: document.getElementById('product-component-1680848013109'),\n" +
        "        moneyFormat: '%24%7B%7Bamount%7D%7D',\n" +
        "        options: {\n" +
        '  "product": {\n' +
        '    "styles": {\n' +
        '      "product": {\n' +
        '        "@media (min-width: 601px)": {\n' +
        '          "max-width": "calc(25% - 20px)",\n' +
        '          "margin-left": "20px",\n' +
        '          "margin-bottom": "50px"\n' +
        "        }\n" +
        "      },\n" +
        '      "button": {\n' +
        '        "font-weight": "bold",\n' +
        '        ":hover": {\n' +
        '          "background-color": "#000000"\n' +
        "        },\n" +
        '        "background-color": "#000000",\n' +
        '        ":focus": {\n' +
        '          "background-color": "#000000"\n' +
        "        }\n" +
        "      }\n" +
        "    },\n" +
        '    "contents": {\n' +
        '      "img": false,\n' +
        '      "title": false,\n' +
        '      "price": false\n' +
        "    },\n" +
        '    "text": {\n' +
        '      "button": "Add to cart"\n' +
        "    }\n" +
        "  },\n" +
        '  "productSet": {\n' +
        '    "styles": {\n' +
        '      "products": {\n' +
        '        "@media (min-width: 601px)": {\n' +
        '          "margin-left": "-20px"\n' +
        "        }\n" +
        "      }\n" +
        "    }\n" +
        "  },\n" +
        '  "modalProduct": {\n' +
        '    "contents": {\n' +
        '      "img": false,\n' +
        '      "imgWithCarousel": true,\n' +
        '      "button": false,\n' +
        '      "buttonWithQuantity": true\n' +
        "    },\n" +
        '    "styles": {\n' +
        '      "product": {\n' +
        '        "@media (min-width: 601px)": {\n' +
        '          "max-width": "100%",\n' +
        '          "margin-left": "0px",\n' +
        '          "margin-bottom": "0px"\n' +
        "        }\n" +
        "      },\n" +
        '      "button": {\n' +
        '        "font-weight": "bold",\n' +
        '        ":hover": {\n' +
        '          "background-color": "#000000"\n' +
        "        },\n" +
        '        "background-color": "#000000",\n' +
        '        ":focus": {\n' +
        '          "background-color": "#000000"\n' +
        "        }\n" +
        "      }\n" +
        "    },\n" +
        '    "text": {\n' +
        '      "button": "Add to cart"\n' +
        "    }\n" +
        "  },\n" +
        '  "option": {},\n' +
        '  "cart": {\n' +
        '    "styles": {\n' +
        '      "button": {\n' +
        '        "font-weight": "bold",\n' +
        '        ":hover": {\n' +
        '          "background-color": "#000000"\n' +
        "        },\n" +
        '        "background-color": "#000000",\n' +
        '        ":focus": {\n' +
        '          "background-color": "#000000"\n' +
        "        }\n" +
        "      }\n" +
        "    },\n" +
        '    "text": {\n' +
        '      "total": "Subtotal",\n' +
        '      "button": "Checkout"\n' +
        "    },\n" +
        '    "contents": {\n' +
        '      "note": true\n' +
        "    }\n" +
        "  },\n" +
        '  "toggle": {\n' +
        '    "styles": {\n' +
        '      "toggle": {\n' +
        '        "font-weight": "bold",\n' +
        '        "background-color": "#000000",\n' +
        '        ":hover": {\n' +
        '          "background-color": "#000000"\n' +
        "        },\n" +
        '        ":focus": {\n' +
        '          "background-color": "#000000"\n' +
        "        }\n" +
        "      }\n" +
        "    }\n" +
        "  }\n" +
        "},\n" +
        "      });\n" +
        "\t  \n" +
        "\t  ui.createComponent('product', {\n" +
        "        id: '8229436031271',\n" +
        "        node: document.getElementById('product-component-1680848087963'),\n" +
        "        moneyFormat: '%24%7B%7Bamount%7D%7D',\n" +
        "        options: {\n" +
        '  "product": {\n' +
        '    "styles": {\n' +
        '      "product": {\n' +
        '        "@media (min-width: 601px)": {\n' +
        '          "max-width": "calc(25% - 20px)",\n' +
        '          "margin-left": "20px",\n' +
        '          "margin-bottom": "50px"\n' +
        "        }\n" +
        "      },\n" +
        '      "button": {\n' +
        '        "font-weight": "bold",\n' +
        '        ":hover": {\n' +
        '          "background-color": "#000000"\n' +
        "        },\n" +
        '        "background-color": "#000000",\n' +
        '        ":focus": {\n' +
        '          "background-color": "#000000"\n' +
        "        }\n" +
        "      }\n" +
        "    },\n" +
        '    "contents": {\n' +
        '      "img": false,\n' +
        '      "title": false,\n' +
        '      "price": false\n' +
        "    },\n" +
        '    "text": {\n' +
        '      "button": "Add to cart"\n' +
        "    }\n" +
        "  },\n" +
        '  "productSet": {\n' +
        '    "styles": {\n' +
        '      "products": {\n' +
        '        "@media (min-width: 601px)": {\n' +
        '          "margin-left": "-20px"\n' +
        "        }\n" +
        "      }\n" +
        "    }\n" +
        "  },\n" +
        '  "modalProduct": {\n' +
        '    "contents": {\n' +
        '      "img": false,\n' +
        '      "imgWithCarousel": true,\n' +
        '      "button": false,\n' +
        '      "buttonWithQuantity": true\n' +
        "    },\n" +
        '    "styles": {\n' +
        '      "product": {\n' +
        '        "@media (min-width: 601px)": {\n' +
        '          "max-width": "100%",\n' +
        '          "margin-left": "0px",\n' +
        '          "margin-bottom": "0px"\n' +
        "        }\n" +
        "      },\n" +
        '      "button": {\n' +
        '        "font-weight": "bold",\n' +
        '        ":hover": {\n' +
        '          "background-color": "#000000"\n' +
        "        },\n" +
        '        "background-color": "#000000",\n' +
        '        ":focus": {\n' +
        '          "background-color": "#000000"\n' +
        "        }\n" +
        "      }\n" +
        "    },\n" +
        '    "text": {\n' +
        '      "button": "Add to cart"\n' +
        "    }\n" +
        "  },\n" +
        '  "option": {},\n' +
        '  "cart": {\n' +
        '    "styles": {\n' +
        '      "button": {\n' +
        '        "font-weight": "bold",\n' +
        '        ":hover": {\n' +
        '          "background-color": "#000000"\n' +
        "        },\n" +
        '        "background-color": "#000000",\n' +
        '        ":focus": {\n' +
        '          "background-color": "#000000"\n' +
        "        }\n" +
        "      }\n" +
        "    },\n" +
        '    "text": {\n' +
        '      "total": "Subtotal",\n' +
        '      "button": "Checkout"\n' +
        "    },\n" +
        '    "contents": {\n' +
        '      "note": true\n' +
        "    }\n" +
        "  },\n" +
        '  "toggle": {\n' +
        '    "styles": {\n' +
        '      "toggle": {\n' +
        '        "font-weight": "bold",\n' +
        '        "background-color": "#000000",\n' +
        '        ":hover": {\n' +
        '          "background-color": "#000000"\n' +
        "        },\n" +
        '        ":focus": {\n' +
        '          "background-color": "#000000"\n' +
        "        }\n" +
        "      }\n" +
        "    }\n" +
        "  }\n" +
        "},\n" +
        "      });" +
        "\t  ui.createComponent('product', {\n" +
        "        id: '8229436784935',\n" +
        "        node: document.getElementById('product-component-1680848235508'),\n" +
        "        moneyFormat: '%24%7B%7Bamount%7D%7D',\n" +
        "        options: {\n" +
        '  "product": {\n' +
        '    "styles": {\n' +
        '      "product": {\n' +
        '        "@media (min-width: 601px)": {\n' +
        '          "max-width": "calc(25% - 20px)",\n' +
        '          "margin-left": "20px",\n' +
        '          "margin-bottom": "50px"\n' +
        "        }\n" +
        "      },\n" +
        '      "button": {\n' +
        '        "font-weight": "bold",\n' +
        '        ":hover": {\n' +
        '          "background-color": "#000000"\n' +
        "        },\n" +
        '        "background-color": "#000000",\n' +
        '        ":focus": {\n' +
        '          "background-color": "#000000"\n' +
        "        }\n" +
        "      }\n" +
        "    },\n" +
        '    "contents": {\n' +
        '      "img": false,\n' +
        '      "title": false,\n' +
        '      "price": false\n' +
        "    },\n" +
        '    "text": {\n' +
        '      "button": "Add to cart"\n' +
        "    }\n" +
        "  },\n" +
        '  "productSet": {\n' +
        '    "styles": {\n' +
        '      "products": {\n' +
        '        "@media (min-width: 601px)": {\n' +
        '          "margin-left": "-20px"\n' +
        "        }\n" +
        "      }\n" +
        "    }\n" +
        "  },\n" +
        '  "modalProduct": {\n' +
        '    "contents": {\n' +
        '      "img": false,\n' +
        '      "imgWithCarousel": true,\n' +
        '      "button": false,\n' +
        '      "buttonWithQuantity": true\n' +
        "    },\n" +
        '    "styles": {\n' +
        '      "product": {\n' +
        '        "@media (min-width: 601px)": {\n' +
        '          "max-width": "100%",\n' +
        '          "margin-left": "0px",\n' +
        '          "margin-bottom": "0px"\n' +
        "        }\n" +
        "      },\n" +
        '      "button": {\n' +
        '        "font-weight": "bold",\n' +
        '        ":hover": {\n' +
        '          "background-color": "#000000"\n' +
        "        },\n" +
        '        "background-color": "#000000",\n' +
        '        ":focus": {\n' +
        '          "background-color": "#000000"\n' +
        "        }\n" +
        "      }\n" +
        "    },\n" +
        '    "text": {\n' +
        '      "button": "Add to cart"\n' +
        "    }\n" +
        "  },\n" +
        '  "option": {},\n' +
        '  "cart": {\n' +
        '    "styles": {\n' +
        '      "button": {\n' +
        '        "font-weight": "bold",\n' +
        '        ":hover": {\n' +
        '          "background-color": "#000000"\n' +
        "        },\n" +
        '        "background-color": "#000000",\n' +
        '        ":focus": {\n' +
        '          "background-color": "#000000"\n' +
        "        }\n" +
        "      }\n" +
        "    },\n" +
        '    "text": {\n' +
        '      "total": "Subtotal",\n' +
        '      "button": "Checkout"\n' +
        "    },\n" +
        '    "contents": {\n' +
        '      "note": true\n' +
        "    }\n" +
        "  },\n" +
        '  "toggle": {\n' +
        '    "styles": {\n' +
        '      "toggle": {\n' +
        '        "font-weight": "bold",\n' +
        '        "background-color": "#000000",\n' +
        '        ":hover": {\n' +
        '          "background-color": "#000000"\n' +
        "        },\n" +
        '        ":focus": {\n' +
        '          "background-color": "#000000"\n' +
        "        }\n" +
        "      }\n" +
        "    }\n" +
        "  }\n" +
        "},\n" +
        "      });\n" +
        "\t  \n" +
        "\t ui.createComponent('product', {\n" +
        "        id: '8229437210919',\n" +
        "        node: document.getElementById('product-component-1680848284701'),\n" +
        "        moneyFormat: '%24%7B%7Bamount%7D%7D',\n" +
        "        options: {\n" +
        '  "product": {\n' +
        '    "styles": {\n' +
        '      "product": {\n' +
        '        "@media (min-width: 601px)": {\n' +
        '          "max-width": "calc(25% - 20px)",\n' +
        '          "margin-left": "20px",\n' +
        '          "margin-bottom": "50px"\n' +
        "        }\n" +
        "      },\n" +
        '      "button": {\n' +
        '        "font-weight": "bold",\n' +
        '        ":hover": {\n' +
        '          "background-color": "#000000"\n' +
        "        },\n" +
        '        "background-color": "#000000",\n' +
        '        ":focus": {\n' +
        '          "background-color": "#000000"\n' +
        "        }\n" +
        "      }\n" +
        "    },\n" +
        '    "contents": {\n' +
        '      "img": false,\n' +
        '      "title": false,\n' +
        '      "price": false\n' +
        "    },\n" +
        '    "text": {\n' +
        '      "button": "Add to cart"\n' +
        "    }\n" +
        "  },\n" +
        '  "productSet": {\n' +
        '    "styles": {\n' +
        '      "products": {\n' +
        '        "@media (min-width: 601px)": {\n' +
        '          "margin-left": "-20px"\n' +
        "        }\n" +
        "      }\n" +
        "    }\n" +
        "  },\n" +
        '  "modalProduct": {\n' +
        '    "contents": {\n' +
        '      "img": false,\n' +
        '      "imgWithCarousel": true,\n' +
        '      "button": false,\n' +
        '      "buttonWithQuantity": true\n' +
        "    },\n" +
        '    "styles": {\n' +
        '      "product": {\n' +
        '        "@media (min-width: 601px)": {\n' +
        '          "max-width": "100%",\n' +
        '          "margin-left": "0px",\n' +
        '          "margin-bottom": "0px"\n' +
        "        }\n" +
        "      },\n" +
        '      "button": {\n' +
        '        "font-weight": "bold",\n' +
        '        ":hover": {\n' +
        '          "background-color": "#000000"\n' +
        "        },\n" +
        '        "background-color": "#000000",\n' +
        '        ":focus": {\n' +
        '          "background-color": "#000000"\n' +
        "        }\n" +
        "      }\n" +
        "    },\n" +
        '    "text": {\n' +
        '      "button": "Add to cart"\n' +
        "    }\n" +
        "  },\n" +
        '  "option": {},\n' +
        '  "cart": {\n' +
        '    "styles": {\n' +
        '      "button": {\n' +
        '        "font-weight": "bold",\n' +
        '        ":hover": {\n' +
        '          "background-color": "#000000"\n' +
        "        },\n" +
        '        "background-color": "#000000",\n' +
        '        ":focus": {\n' +
        '          "background-color": "#000000"\n' +
        "        }\n" +
        "      }\n" +
        "    },\n" +
        '    "text": {\n' +
        '      "total": "Subtotal",\n' +
        '      "button": "Checkout"\n' +
        "    },\n" +
        '    "contents": {\n' +
        '      "note": true\n' +
        "    }\n" +
        "  },\n" +
        '  "toggle": {\n' +
        '    "styles": {\n' +
        '      "toggle": {\n' +
        '        "font-weight": "bold",\n' +
        '        "background-color": "#000000",\n' +
        '        ":hover": {\n' +
        '          "background-color": "#000000"\n' +
        "        },\n" +
        '        ":focus": {\n' +
        '          "background-color": "#000000"\n' +
        "        }\n" +
        "      }\n" +
        "    }\n" +
        "  }\n" +
        "},\n" +
        "      });\n" +
        "\t  \n" +
        "    });\n" +
        "  }\n" +
        "})();\n" +
        "/*]]>*/";

      document.head.appendChild(shopifyBuy);
    }
  },

  data() {
    return {
      buyActive: false,
      oldversion: false,
      mgo1050Image: require("../../assets/images/wero clearcuts 1050mgo cropped box.png"),
      mgo800Image: require("../../assets/images/wero clearcuts 800mgo cropped box.png"),
      mgo600Image: require("../../assets/images/wero clearcuts 600mgo cropped box.png"),
      mgo500Image: require("../../assets/images/wero clearcuts 500mgo cropped.png"),
      mgo400Image: require("../../assets/images/wero clearcuts 400mgo cropped.png"),
      mgo300Image: require("../../assets/images/wero clearcuts 300mgo cropped.png"),
      mgo200Image: require("../../assets/images/wero clearcuts 200mgo cropped.png"),
      losengesImage: require("../../assets/images/lozenges Box.png"),
    };
  },

  methods: {
    clickCart: function () {
      // Simulates a click on the cart button
      const cartElement = document.getElementsByClassName("shopify-buy-frame--toggle")[0];
      console.info(cartElement);
      cartElement.click();
    },
  },

  computed: {
    vuex: function () {
      return this.$store.state;
    },
  },

  watch: {},
};
</script>

<style scoped>
.header-wrapper {
  width: 100%;
  text-align: center;
}

.product-card-wrapper {
  display: flex; /*sets equal heights the easy way*/
}

.card-title {
  font-size: 2rem;
  font-family: hubballi;
  font-weight: 800;
}
.card-subtitle {
  font-size: 1.2rem;
  font-family: hubballi;
  font-weight: 500;
}
.honey-card-text {
  font-family: acumin-pro, sans-serif !important;
  font-size: 0.85rem;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 1.4rem;
  text-align: justify;
  margin-top: -1rem;
}

.warning-wrapper {
  width: 100%;
}

.product-image {
  padding: 1rem;
  max-width: 350px;
}

.buy-button {
  width: 100%;
  margin: 0 auto;
}
</style>
