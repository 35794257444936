<template>
  <div class="video-outer-wrapper" :style="videoHeightStyle">
    <div class="fullscreen">
      <video autoplay loop muted disableRemotePlayback playsinline ref="videoRef" id="bannerVideo" :poster="posterImage">
        <source :src="videoWebm" type="video/webm" />
        <source :src="videoMp4" type="video/mp4" />
      </video>
      <div class="text-panel">
        <div class="text-panel-inner">
          <img class="logo" :src="whiteLogo">
          <h1 style="text-shadow: 5px 0px 5px rgba(17,17,17,0.83);">Nau mai haere mai<br>Welcome</h1>
          <i @click="scrollDown()" class="pi pi-arrow-circle-down" style="font-size: 2.5rem; z-index: 10;"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoBanner',

  props: {

  },

  mounted() {
    // Fix for mobile navigation bar hiding on scroll
    // https://stackoverflow.com/questions/17328742/mobile-chrome-fires-resize-event-on-scroll/17328813#17328813
    this.setVideoHeight();
    this.initialWindowWidth = window.innerWidth;

    const self = this;
    window.addEventListener("resize", function() {
      this.resizeVideoHeight();
    }.bind(self));
  },

  data() {
    return {
      posterImage: require("../../assets/videos/poster.png"),
      videoMp4: require("../../assets/videos/video-banner.mp4"),
      videoWebm: require("../../assets/videos/video-banner.webm"),
      whiteLogo: require("../../assets/images/White logo bee.png"),
      videoHeightStyle: "height: 100%;",
      initialWindowWidth: null,
    }
  },

  methods: {
    setVideoHeight: function(){
      // sets the height of the video background to be fullscreen - navbar
      const navbarHeight = this.vuex.navbarHeight;
      const screenHeight = this.vuex.viewHeight;
      this.videoHeightStyle = "height: " + (screenHeight - navbarHeight) + "px;";
    },

    resizeVideoHeight: function(){
      // Fix for mobile navigation bar hiding on scroll, and mobile keyboard too
      // https://stackoverflow.com/questions/17328742/mobile-chrome-fires-resize-event-on-scroll/17328813#17328813
      if(this.initialWindowWidth !== window.innerWidth){
        const navbarHeight = this.vuex.navbarHeight;
        const screenHeight = this.vuex.viewHeight;
        this.videoHeightStyle = "height: " + (screenHeight - navbarHeight) + "px;";
        this.initialWindowWidth = window.innerWidth;
      }
    },

    scrollDown: function(){
      // Scrolls the page to the about section
      let ourStory = document.getElementById("story");
      if(ourStory){
        window.scrollTo({top: ourStory.offsetTop, behavior: 'smooth'})
      }
    }

  },

  computed: {
    vuex: function(){
      return(this.$store.state);
    },
  },

  watch: {
    vuex(){
      // This fixes an edge case where a mobile device is rotated, it does not fire the resize event for some reason (chrome)
      this.resizeVideoHeight();
    }
  }

}

</script>


<style scoped>
.video-outer-wrapper{
  position: relative;
  overflow: hidden;
  height: 100%; /* height is dynamically set via JS to account for navbar */
  width: 100%;
}
.fullscreen{
  height: 100%;
  width: 100%;
  padding:0;
  margin: 0;
  background-color: gray;
  position: relative;
}
#bannerVideo{
  width: 100vw;
  height: auto;
  margin: auto;
  display: block;
}
@media (min-aspect-ratio: 16/9) {
  #bannerVideo{
    width: 100vw;
    height:auto;
  }
}

@media (max-aspect-ratio: 16/9) {
  #bannerVideo {
    height: 100vh;
    width: auto;
    margin-left: 50vw;
    transform: translate(-50%);
  }
}
.text-panel{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-family: Roboto Condensed, sans-serif;
  font-size: 1rem;
}
.text-panel-inner{
  /*background-color: rgba(29, 29, 29, 0.40);*/
  color: rgba(250, 250, 250, 0.85);
  padding: 15px;
  border-radius: 5px;
}

.logo{
  width: 200px;
  max-width: 50vw;
}



</style>


