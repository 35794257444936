<template>
  <div v-if="!vuex.isMobile" class="menu-bar-custom" id="menuBar" :style="'background-image: url(' + this.background + ');'">
    <router-link :to="'/'"><img :src="logo" /></router-link>
    <ul role="menubar">
      <li role="none" class="mobile-menu-text"><router-link :to="'/'">HOME</router-link></li>
      <li role="none" class="mobile-menu-text"><router-link :to="'/products'">PRODUCTS</router-link></li>
      <li role="none" class="mobile-menu-text"><router-link :to="'/why-manuka'">WHY MĀNUKA</router-link></li>
      <li role="none" class="mobile-menu-text"><router-link :to="'/about'">ABOUT US</router-link></li>
      <li role="none" class="mobile-menu-text"><router-link :to="'/contact'">CONTACT</router-link></li>
    </ul>
  </div>

  <div v-if="vuex.isMobile" class="menu-bar-custom-mobile" id="menuBar" :style="'background-image: url(' + this.background + ');'">
    <div class="grid" style="margin: auto 0; padding: 0; text-align: center">
      <div class="col-8">
        <router-link :to="'/'"><img :src="logo" style="max-width: 100%" /></router-link>
      </div>
      <div class="col-4" style="margin: auto 0; padding: 0">
        <i @click="toggleMobileMenu" class="pi pi-bars" style="font-size: 2rem"></i>
      </div>
    </div>
  </div>

  <div v-if="vuex.isMobile && showMobileMenu" class="mobile-menu-expanded" id="mobileMenuExpanded">
    <ul role="menubar">
      <router-link :to="'/'"><li @click="toggleMobileMenu" role="none">HOME</li></router-link>
      <router-link :to="'/products'"><li @click="toggleMobileMenu" role="none">PRODUCTS</li></router-link>
      <router-link :to="'/about'"><li @click="toggleMobileMenu" role="none">ABOUT US</li></router-link>
      <router-link :to="'/why-manuka'"><li @click="toggleMobileMenu" role="none">WHY MĀNUKA</li></router-link>
      <router-link :to="'/contact'"><li @click="toggleMobileMenu" role="none">CONTACT</li></router-link>
    </ul>
  </div>
</template>

<script>
export default {
  name: "NavigationBar",

  mounted() {
    // Make sure we store the nav height
    this.storeNavHeight();

    const self = this;
    window.addEventListener(
      "resize",
      function () {
        this.storeNavHeight();
      }.bind(self)
    );

    this.$nextTick(() => {
      // The whole view is rendered, so I can safely access or query the DOM. ¯\_(ツ)_/¯
      this.storeNavHeight();
    });
  },

  data() {
    return {
      background: require("../assets/images/banner.jpg"),
      logo: require("../assets/images/Wero Logo White.png"),

      showMobileMenu: false,

      items: [
        {
          label: "HOME",
          to: "/",
        },
        {
          label: "PRODUCTS",
          to: "/products",
        },
        {
          label: "OUR STORY",
          to: "/our-story",
        },
        {
          label: "GALLERY",
          to: "/gallery",
        },
        {
          label: "CONTACT",
          to: "/contact",
        },
      ],
    };
  },

  methods: {
    toggleMobileMenu() {
      // Controls the click to open mobile menu
      this.showMobileMenu = !this.showMobileMenu;
    },

    storeNavHeight: function () {
      // Store the navbar height into vuex
      let height = document.getElementById("menuBar").clientHeight;
      this.$store.commit("updateNavigationHeight", height);
    },
  },

  computed: {
    vuex: function () {
      return this.$store.state;
    },
  },

  watch: {},
};
</script>

<style scoped>
.menu-bar-custom {
  width: 100%;
  height: 110px;
  margin: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  border-bottom: 1px solid #3c3d40;
  background-position: center;
  color: white;
}
.menu-bar-custom-mobile {
  width: 100%;
  height: 80px;
  margin: 0;
  padding-top: 2px;
  padding-bottom: 2px;
  text-align: center;
  border-bottom: 1px solid #3c3d40;
  background-position: center;
  color: white;
}
.menu-bar-custom ul {
  padding: 0;
  margin-top: 20px;
  list-style: none;
  max-height: 100%;
  display: inline-flex;
  verticle-align: middle;
}
.menu-bar-custom img {
  max-height: 90px;
  display: inline-flex;
  vertical-align: middle;
}
.menu-bar-custom-mobile img {
  max-height: 50px;
  display: inline-flex;
  vertical-align: middle;
}
.menu-bar-custom li {
  display: inline;
}
.menu-bar-custom a {
  padding: 1rem;
  color: white;
  text-decoration: none;
  margin: 0 auto;
  font-family: hubballi;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 0.9rem;
}
.menu-bar-custom a:hover {
  text-decoration: underline;
}
.menu-bar-custom ul > li:nth-child(1) > a {
  padding-left: 100px;
}
.mobile-menu-expanded {
  background-color: black;
  margin: 0;
  padding: 0;
  z-index: 99;
  position: absolute;
  width: 100%;
}
.mobile-menu-expanded ul {
  margin: 0;
  padding: 0;
  text-align: center;
}
.mobile-menu-expanded ul li {
  text-decoration: none;
  padding-top: 1.3rem;
  color: white;
}
.mobile-menu-expanded ul li:last-of-type {
  padding-bottom: 1.3rem;
}
.mobile-menu-expanded ul a li {
  text-decoration: none;
  color: white;
  text-decoration: none;
  font-family: Roboto Condensed, sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 0.75rem;
  text-decoration: none;
  text-underline: none;
}

a {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:focus {
  text-decoration: none;
}
a:hover,
a:active {
  text-decoration: none;
}
</style>
