import { createApp } from "vue";
import { createStore } from "vuex";
import { createWebHistory, createRouter } from "vue-router";
import axios from "axios";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import VueSplide from "@splidejs/vue-splide";
import AOS from "aos";
import "aos/dist/aos.css";

import "primevue/resources/themes/md-light-indigo/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons"
import "primeflex/primeflex.css";
import "/node_modules/primeflex/primeflex.css";

import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Menubar from "primevue/menubar";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import Message from "primevue/message";
import Checkbox from "primevue/checkbox";
import MultiSelect from "primevue/multiselect";
import Avatar from "primevue/avatar";
import AvatarGroup from "primevue/avatargroup";
import Panel from "primevue/panel";
import Knob from "primevue/knob";
import Dialog from "primevue/dialog";
import Badge from "primevue/badge";
import Card from "primevue/card";
import Carousel from "primevue/carousel";
import Fieldset from "primevue/fieldset";
import Divider from "primevue/divider";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Chip from "primevue/chip";
import Galleria from "primevue/galleria";
import Timeline from "primevue/timeline";
import Textarea from "primevue/textarea";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import OverlayPanel from "primevue/overlaypanel";
import Slider from "primevue/slider";

import App from "./App.vue";
import Home from "./views/Home.vue";
import Products from "./views/Products";
import About from "./views/About";
import Contact from "./views/Contact";
import Traceability from "./views/Traceability";
import Why from "./views/Why";
import Generateqr from "./views/Generateqr";

const routes = [
  {
    path: "/",
    component: Home,
    name: "default",
  },
  {
    path: "/home",
    component: Home,
    name: "Home",
  },
  {
    path: "/products",
    component: Products,
    name: "Products",
  },

  {
    path: "/about",
    component: About,
    name: "About",
  },

  {
    path: "/why-manuka",
    component: Why,
    name: "Why",
  },

  {
    path: "/contact",
    component: Contact,
    name: "Contact",
  },

  {
    path: "/trace/:code",
    component: Traceability,
    name: "Traceability",
  },

  {
    path: "/generateqr/",
    component: Generateqr,
    name: "Generateqr",
  },
];

const router = createRouter({
  routes,
  history: createWebHistory(),
});

// Create a new store instance.
const store = createStore({
  state() {
    return {
      // Responsive parameters
      viewWidth: null,
      viewHeight: null,
      isMobile: null,
      navbarHeight: null,
      footerHeight: null,
      navFooterCombinedHeight: null,
    };
  },
  mutations: {
    updateSizes(state, object) {
      // Update responsive parameters on window resize
      state.viewWidth = object.width;
      state.viewHeight = object.height;
      state.isMobile = object.isMobile;
      state.navFooterCombinedHeight = object.navFooterCombinedHeight;
    },
    updateNavigationHeight(state, height) {
      // Update the navbar height
      state.navbarHeight = height;
    },
    updateFooterHeight(state, height) {
      // Update the footer height
      state.footerHeight = height;
    },
  },
});

const app = createApp(App);
app.use(PrimeVue);
app.use(ToastService);
app.use(router);
app.use(store);
app.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyB7jplHAwuOhT2EPa98JepDwM_3u5xdG4U",
  },
});
app.use(VueSplide);
app.AOS = new AOS.init();

app.component("InputText", InputText);
app.component("Button", Button);
app.component("Menubar", Menubar);
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("ColumnGroup", ColumnGroup);
app.component("Message", Message);
app.component("Checkbox", Checkbox);
app.component("MultiSelect", MultiSelect);
app.component("Avatar", Avatar);
app.component("AvatarGroup", AvatarGroup);
app.component("Panel", Panel);
app.component("Knob", Knob);
app.component("Dialog", Dialog);
app.component("Badge", Badge);
app.component("Card", Card);
app.component("Carousel", Carousel);
app.component("Fieldset", Fieldset);
app.component("Divider", Divider);
app.component("TabPanel", TabPanel);
app.component("TabView", TabView);
app.component("Chip", Chip);
app.component("Galleria", Galleria);
app.component("Timeline", Timeline);
app.component("Textarea", Textarea);
app.component("Accordion", Accordion);
app.component("AccordionTab", AccordionTab);
app.component("OverlayPanel", OverlayPanel);
app.component("Slider", Slider);

app.config.globalProperties.axios = axios;
app.mount("#app");
