<template>
  <div class="wrapper center-text light-text top-padding">
    <h2 class="top-padding bottom-padding">WHO WE ARE</h2>
    <p class="info-text">
      Here at Wero NZ Mānuka Honey, we love to share pictures of all the people, things and special moments that have helped guide us and shape our
      business into what it is today.
    </p>
  </div>

  <div class="grid grid-nogutter justify-content-center bottom-padding top-padding">
    <div class="col-12 md:col-10 lg:col-8">
      <Splide class="center-images" :options="slideShowOptions" ref="splideRef" aria-label="Gallery Images">
        <SplideSlide>
          <div class="slide-container">
            <img :src="img1" alt="" class="slider-image" />
            <div class="slide-caption">
              <span>{{ img1Caption }}</span>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="slide-container">
            <img :src="img2" alt="" class="slider-image" />
            <div class="slide-caption">
              <span>{{ img2Caption }}</span>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="slide-container">
            <img :src="img3" alt="" class="slider-image" />
            <div class="slide-caption">
              <span>{{ img3Caption }}</span>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="slide-container">
            <img :src="img4" alt="" class="slider-image" />
            <div class="slide-caption">
              <span>{{ img4Caption }}</span>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="slide-container">
            <img :src="img5" alt="" class="slider-image" />
            <div class="slide-caption">
              <span>{{ img5Caption }}</span>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="slide-container">
            <img :src="img6" alt="" class="slider-image" />
            <div class="slide-caption">
              <span>{{ img6Caption }}</span>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="slide-container">
            <img :src="img7" alt="" class="slider-image" />
            <div class="slide-caption">
              <span>{{ img7Caption }}</span>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="slide-container">
            <img :src="img8" alt="" class="slider-image" />
            <div class="slide-caption">
              <span>{{ img8Caption }}</span>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="slide-container">
            <img :src="img9" alt="" class="slider-image" />
            <div class="slide-caption">
              <span>{{ img9Caption }}</span>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="slide-container">
            <img :src="img10" alt="" class="slider-image" />
            <div class="slide-caption">
              <span>{{ img10Caption }}</span>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="slide-container">
            <img :src="img11" alt="" class="slider-image" />
            <div class="slide-caption">
              <span>{{ img11Caption }}</span>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="slide-container">
            <img :src="img12" alt="" class="slider-image" />
            <div class="slide-caption">
              <span>{{ img12Caption }}</span>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="slide-container">
            <img :src="img13" alt="" class="slider-image" />
            <div class="slide-caption">
              <span>{{ img13Caption }}</span>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="slide-container">
            <img :src="img14" alt="" class="slider-image" />
            <div class="slide-caption">
              <span>{{ img14Caption }}</span>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="slide-container">
            <img :src="img15" alt="" class="slider-image" />
            <div class="slide-caption">
              <span>{{ img15Caption }}</span>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="slide-container">
            <img :src="img16" alt="" class="slider-image" />
            <div class="slide-caption">
              <span>{{ img16Caption }}</span>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="slide-container">
            <img :src="img17" alt="" class="slider-image" />
            <div class="slide-caption">
              <span>{{ img17Caption }}</span>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="slide-container">
            <img :src="img18" alt="" class="slider-image" />
            <div class="slide-caption">
              <span>{{ img18Caption }}</span>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="slide-container">
            <img :src="img19" alt="" class="slider-image" />
            <div class="slide-caption">
              <span>{{ img19Caption }}</span>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="slide-container">
            <img :src="img20" alt="" class="slider-image" />
            <div class="slide-caption">
              <span>{{ img20Caption }}</span>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="slide-container">
            <img :src="img21" alt="" class="slider-image" />
            <div class="slide-caption">
              <span>{{ img21Caption }}</span>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="slide-container">
            <img :src="img22" alt="" class="slider-image" />
            <div class="slide-caption">
              <span>{{ img22Caption }}</span>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="slide-container">
            <img :src="img23" alt="" class="slider-image" />
            <div class="slide-caption">
              <span>{{ img23Caption }}</span>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="slide-container">
            <img :src="img24" alt="" class="slider-image" />
            <div class="slide-caption">
              <span>{{ img24Caption }}</span>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="slide-container">
            <img :src="img25" alt="" class="slider-image" />
            <div class="slide-caption">
              <span>{{ img25Caption }}</span>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="slide-container">
            <img :src="img26" alt="" class="slider-image" />
            <div class="slide-caption">
              <span>{{ img26Caption }}</span>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="slide-container">
            <img :src="img27" alt="" class="slider-image" />
            <div class="slide-caption">
              <span>{{ img27Caption }}</span>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="slide-container">
            <img :src="img28" alt="" class="slider-image" />
            <div class="slide-caption">
              <span>{{ img28Caption }}</span>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="slide-container">
            <img :src="img29" alt="" class="slider-image" />
            <div class="slide-caption">
              <span>{{ img29Caption }}</span>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="slide-container">
            <img :src="img30" alt="" class="slider-image" />
            <div class="slide-caption">
              <span>{{ img30Caption }}</span>
            </div>
          </div>
        </SplideSlide>
      </Splide>
      <div class="slideshow-controls">
        <div v-if="!slideShowPaused" style="display: inline-block; float: left" @click="togglePlay()">
          <i class="pi pi-pause" style="font-size: 1.5rem; color: whitesmoke"></i>
        </div>
        <div v-else style="display: inline-block; float: left" @click="togglePlay()">
          <i class="pi pi-play" style="font-size: 1.5em; color: whitesmoke"></i>
        </div>
        <div v-if="musicPlaying" style="display: inline-block; float: right" @click="toggleMusic()">
          <i class="pi pi-volume-up" style="font-size: 1.5rem; color: whitesmoke"></i>
        </div>
        <div v-else style="display: inline-block; float: right" @click="toggleMusic()">
          <i class="pi pi-volume-off" style="font-size: 1.5em; color: whitesmoke"></i>
        </div>
      </div>
      <audio controls style="display: none" id="musicPlayer">
        <source :src="weroSong" type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>
    </div>
  </div>
</template>

<script>
export default {
  name: "GallerySection",

  props: {},

  mounted() {},

  data() {
    return {
      musicPlaying: false,
      slideShowPaused: true,

      slideShowOptions: {
        rewind: true,
        autoWidth: false,
        autoplay: false,
        interval: 4000,
        keyboard: true,
        perPage: 1,
        pauseOnHover: false,
        perMove: 1,
        arrows: true,
      },

      weroSong: require("../../assets/sounds/bensound-tenderness.mp3"),
      img1: require("../../assets/images/gallery/1.png"),
      img1Caption: "Sunrise at Uriti Beach in the Wairarapa",
      img2: require("../../assets/images/gallery/2.png"),
      img2Caption: "Paddy's ātaahua/beautiful mother, Te Ana Reiri",
      img3: require("../../assets/images/gallery/3.png"),
      img3Caption: "Paddy's father, Paddy Mason Snr at home with his mokopuna/grandchildren at Uriti Beach, in the Wairarapa",
      img4: require("../../assets/images/gallery/4.jpg"),
      img4Caption:
        "Omar Mohamed AlGhfeli from Dubai Exports, with his whānau/family enjoying their beautiful Wero Mānuka Honey, on their farm in Dubai",
      img5: require("../../assets/images/gallery/5.jpg"),
      img5Caption: "Our bee keepers, Johnny, Eden, Chance, Andrew & Paddy",
      img6: require("../../assets/images/gallery/6.png"),
      img6Caption: "Ngakita Ngatai-Matapuku and Ana Mason, amongst native harakeke/flax in Wairarapa",
      img7: require("../../assets/images/gallery/7.jpg"),
      img7Caption: "Kahungunu Tribal Leader - Ngahiwi Tomoana and wife Mere Tomoana, loving their silky smooth Wero Mānuka Honey",
      img8: require("../../assets/images/gallery/8.jpg"),
      img8Caption: "Paddy in the field checking on his precious bees",
      img9: require("../../assets/images/gallery/9.jpg"),
      img9Caption: "Tamati and Rikihana Mason, Our Future",
      img10: require("../../assets/images/gallery/10.png"),
      img10Caption: "Bee Keeper,  Eden Smith",
      img11: require("../../assets/images/gallery/11.jpg"),
      img11Caption:
        "Wero Partners - Dominic Blackie (General Manager) and Katherine Brundell (Chief Revenue Officer) for Oha Honey, pictured with Wero whānau/family",
      img12: require("../../assets/images/gallery/12.jpg"),
      img12Caption: "Oha Extraction Lead Supervisor, Matariki Paewhenua",
      img13: require("../../assets/images/gallery/13.png"),
      img13Caption: "Ana and Ngakita in the ngahere/bush in the Wairarapa",
      img14: require("../../assets/images/gallery/14.jpg"),
      img14Caption: "Marley at mahi/work with her dad Johnny",
      img15: require("../../assets/images/gallery/15.png"),
      img15Caption: "Paddy's son Matene and his tamariki/children Te Ana, Maci and Rikihana",
      img16: require("../../assets/images/gallery/16.jpg"),
      img16Caption: "Bee Keeper,  Eden Smith",
      img17: require("../../assets/images/gallery/17.png"),
      img17Caption: "Whānau/family, Hayden Hape, pictured with our Emirati whānau/family in Dubai",
      img18: require("../../assets/images/gallery/18.jpg"),
      img18Caption: "Ngahiwi Tomoana, Paddy and Ana pictured with New Zealand Prime Minister Jacinda Arden",
      img19: require("../../assets/images/gallery/19.jpg"),
      img19Caption: "Sheikh Majid Al Qassimi with Paddy Mason at the New Zealand Pavilion, in Dubai",
      img20: require("../../assets/images/gallery/20.png"),
      img20Caption: "Bee Keeper, Johnny Te Tau",
      img21: require("../../assets/images/gallery/21.png"),
      img21Caption: "Minister Nanaia Mahuta at breakfast with Wero director, Paddy Mason at Te Aratini World Expo, Dubai",
      img22: require("../../assets/images/gallery/22.jpg"),
      img22Caption: "Flying bee hives into remote locations",
      img23: require("../../assets/images/gallery/23.jpg"),
      img23Caption: "Rikihana - our future Wero Bee Keeper",
      img24: require("../../assets/images/gallery/24.png"),
      img24Caption: "Arana Mason, Ana and Ngakita, pictured at Te Ore Ore Marae, Masterton",
      img25: require("../../assets/images/gallery/25.jpg"),
      img25Caption: "Paddy catching up with Robin Haab from Mataikona Station, before Robin departs for Switzerland with Wero Honey",
      img26: require("../../assets/images/gallery/26.jpg"),
      img26Caption: "Bee Keeper, Chance Ropiha",
      img27: require("../../assets/images/gallery/27.png"),
      img27Caption: "Wero Administrator - Emma Mason and Poppy",
      img28: require("../../assets/images/gallery/28.jpg"),
      img28Caption: "Our whānau at Te Ore Ore Marae, in Wairarapa",
      img29: require("../../assets/images/gallery/29.png"),
      img29Caption:
        "Ngahiwi Tomoana has been the mandated tribal leader for his Iwi Ngāti Kahungunu for the past 23 years.  He is also the Chair of Pou Tahua, the trade & economic development portfolio of the National Iwi Chairs Forum.  Ngahiwi is the visionary behind Te Aratini, the first Festival of Tribal and Indigenous Ideas to be held at an International World Expo.  He is pictured standing next to the taonga gifted to the United Arab Emirates as the host of the Expo in Dubai",
      img30: require("../../assets/images/gallery/30.jpg"),
      img30Caption: "Wero Honey Director - Paddy Mason, presenting at Te Aratini World Expo, in Dubai",
    };
  },

  methods: {
    togglePlay: function () {
      // Toggle the slideshow playing
      // Change the state
      this.slideShowPaused = !this.slideShowPaused;
      // Update the slider with new state behaviour
      if (this.slideShowPaused) {
        this.$refs.splideRef.splide.Components.Autoplay.pause();
      } else {
        this.$refs.splideRef.splide.Components.Autoplay.play();
      }
    },

    toggleMusic: function () {
      let music = document.getElementById("musicPlayer");
      this.musicPlaying = !this.musicPlaying;
      // Update the slider with new state behaviour
      if (this.musicPlaying) {
        music.play();
      } else {
        music.pause();
      }
    },
  },

  computed: {
    vuex: function () {
      return this.$store.state;
    },
  },

  watch: {},
};
</script>

<style scoped>
@import "~@splidejs/vue-splide/dist/css/splide-core.min.css";
@import "~@splidejs/vue-splide/dist/css/splide.min.css";

.light-text {
  color: whitesmoke;
}

.info-text {
  font-family: acumin-pro, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 1.4rem;
}

.center-images {
  text-align: center;
}

.slideshow-controls {
  margin-top: 0px;
  margin-bottom: 2rem;
  padding: 30px 1rem;
  z-index: 99;
  position: relative;
  height: 24px;
  background-color: black;
}

.pi {
  margin-top: -109px;
}

.slider-image {
  width: 100%;
}
.slide-container {
  position: relative;
  color: white;
  padding: 0;
  margin: 0;
}

.slide-caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 15px 15px 15px 15px;
  vertical-align: bottom;
  text-align: center;
  font-family: Roboto Condensed, sans-serif;
  font-family: acumin-pro, sans-serif;
  color: whitesmoke;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 1px;
}

/* Small device fix captions */
@media (max-width: 550px) {
  .slide-caption {
    padding: 15px 15px 15px 15px;
    font-family: Roboto Condensed, sans-serif;
    font-family: acumin-pro, sans-serif;
    font-size: 0.7rem;
    font-weight: 300;
    letter-spacing: 1px;
    text-align: justify;
  }
}
</style>
