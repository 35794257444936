<template>
  <div class="grid bottom-padding top-padding" style="align-items: stretch">
    <div class="col-12 md:col-6 lg:col-7 xl:col-8" data-aos="zoom-in" data-aos-once="true">
      <div :style="centerOnMobile">
        <h2>WELCOME</h2>
      </div>

      <p class="paragraph-text">
        Welcome to Wero New Zealand Mānuka Honey! Our home is nestled in the stunning Wairarapa region of New Zealand. We're deeply connected to this
        land, drawing inspiration from its breathtaking beauty and vibrant culture.
      </p>
      <p class="paragraph-text">
        Since 2017, we've been on a mission to craft exceptional Mānuka honey while supporting our local community. It's about more than just honey
        for us. It's about preserving traditions, creating opportunities, and sharing the wonders of our homeland with the world.
      </p>
    </div>

    <div class="col-0 lg:col-1 xl:col-1"></div>

    <div class="col-12 md:col-6 lg:col-4 xl:col-2" data-aos="fade-left" data-aos-once="true">
      <img :src="locationImage" style="width: 100%; margin-top: 3rem" />
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutBlurb",

  props: {},

  mounted() {},

  data() {
    return {
      locationImage: require("../../assets/images/wero-location.jpg"),
    };
  },

  methods: {},

  computed: {
    vuex: function () {
      return this.$store.state;
    },
    centerOnMobile: function () {
      if (this.vuex.isMobile) {
        return "width: 100%; display: flex; justify-content: center;";
      } else {
        return "width: 100%";
      }
    },
  },

  watch: {},
};
</script>

<style scoped>
.values-card {
  max-width: 100% !important;
}

.values-card > .p-card {
  border: none;
  box-shadow: none;
  text-align: center;
}

.values-card-p {
  text-align: justify;
}

.img-border {
  box-shadow: 1px 1px 19px rgb(0 0 0 / 30%);
  border-style: solid;
  border-width: 13px;
  border-color: #ffffff;
  background-color: transparent;
}

.heading {
  padding-bottom: 45px;
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: flex-start; /* align top */
}

.maori-word {
  font-weight: 600;
}
.english-translation {
  font-weight: 400;
  font-size: 1rem;
}
</style>
