<template>
  <div class="grid grid-nogutter justify-content-center edge-padding page-wrapper">
    <div class="col-12 md:col-10 lg:col-10 flex-column">
      <about-blurb></about-blurb>
    </div>

    <div class="col-12 md:col-10 lg:col-10 flex-column">
      <values-section></values-section>
    </div>
  </div>

  <div class="grid grid-nogutter justify-content-center edge-padding page-wrapper black-bg">
    <div class="border-test" :style="'background-image: url(\'' + border + '\');'"></div>
    <div class="col-12 md:col-10 lg:col-10 flex-column">
      <gallery-section></gallery-section>
    </div>
  </div>
</template>

<script>
import ValuesSection from "../components/about/ValuesSection";
import GallerySection from "../components/about/GallerySection";
import AboutBlurb from "../components/about/AboutBlurb";
export default {
  name: "Products",

  components: {
    GallerySection,
    ValuesSection,
    AboutBlurb,
  },

  mounted() {},

  data() {
    return {
      border: require("../assets/images/border.png"),
    };
  },

  methods: {},

  computed: {
    vuex: function () {
      return this.$store.state;
    },
  },

  watch: {},
};
</script>

<style scoped>
.black-bg {
  background: black;
  position: relative;
}
</style>
