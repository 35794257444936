<template>
  <div class="grid grid-nogutter mb-0 md:mb-8" style="margin: 0 -1em">
    <div class="col-12 bg-gray-900">
      <div class="grid grid-nogutter">
        <div class="col-12 md:col-8 xl:col-9">
          <div
            class="pr-0 lg:pr-6 mb-0 lg:mb-0 px-4 pt-8 pb-4 md:pt-8 md:pb-8 md:px-6 lg:px-8 border-custom"
            :style="'background-image: url(\'' + border + '\');'"
          >
            <div class="font-bold text-white text-6xl font-bold mb-3">Mānuka Honey in Bulk</div>
            <h3 class="text-gray-300 line-height-3 mb-4">Contact us now for wholesale pricing and place your order today!</h3>
            <div class="flex flex-wrap lg:flex-nowrap">
              <Button
                @click="routeToContact"
                label="Contact us today"
                icon="pi pi-chevron-right"
                iconPos="right"
                class="p-button-rounded mr-2 p-button-outlined white-button"
              ></Button>
            </div>
          </div>
        </div>
        <div class="col-12 md:col-4 xl:col-3 wholesale-banner" :style="'background-image: url(\'' + wholesaleImage + '\');'"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BottomSection",

  props: {},

  mounted() {},

  data() {
    return {
      wholesaleImage: require("../../assets/images/assets/wholesale-honey.jpg"),
      border: require("../../assets/images/border.png"),
    };
  },

  methods: {
    routeToContact: function () {
      // Change to products page - and scroll to top !
      this.$router.push({ name: "Contact" });
      window.scrollTo(0, 0);
    },
  },

  computed: {
    vuex: function () {
      return this.$store.state;
    },
  },

  watch: {},
};
</script>

<style scoped>
@media only screen and (max-width: 767px) {
  .wholesale-banner {
    background-position: center;
    background-size: cover;
    min-height: 450px;
    border: 10px solid var(--gray-900);
    border-radius: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .wholesale-banner {
    background-position: center;
    background-size: cover;
  }
}

.white-button {
  color: #ffffff;
}

.white-button:hover {
  background-color: #ffffff !important;
  color: #000000 !important;
}

.border-custom {
  background-repeat: repeat-x;
  background-position-y: top;
  background-size: auto 27px;
  width: 100%;
  opacity: 0.85;
}
</style>
