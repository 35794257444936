<template>
  <div class="grid grid-nogutter justify-content-center dark-bg">

    <div class="border-test" :style=" 'background-image: url(\'' + border + '\');' "></div>

    <div class="col-12 md:col-10" data-aos="fade-right" data-aos-once="true">
      <div class="grid justify-content-center edge-padding center-vertical">
        <div class="col-12 lg:col-6 center-text">
          <div class="center-text top-padding girls-image">
            <img :src="girlsImage" class="img-border girls-image">
            <p class="informational-text">Ana & Ngakita from the Wero Family</p>
          </div>
        </div>
        <div class="col-12 lg:col-6" data-aos="fade-up" data-aos-once="true">
          <h2 style="text-align:center;">Contact</h2>
          <p class="informational-text" style="text-align:center; font-size: 1.1rem; margin-bottom: 20px; margin-top: 0; padding-top:0px; line-height: 1.1;">
            Kia Ora and hello,<br>
            Our team here at Wero would love to hear from you,<br>
            Please feel free to leave a message and we will get back to you as soon as we can,<br>
            Ngā Mihinui, thanks to all.<br>
          </p>
          <div class="grid">
            <div class="col-12" style="text-align: left;">
              <InputText v-model="from_name" type="text" class="p-inputtext-sm" placeholder="Your Name" style="width:100%"/>
            </div>
            <div class="col-12" style="text-align: left;">
              <InputText v-model="reply_to" type="text" class="p-inputtext-sm" placeholder="Your Email" style="width:100%"/>
            </div>
            <div class="col-12" style="text-align: left;">
              <Textarea v-model="message" :autoResize="true" rows="10" cols="50" placeholder="Message" style="width: 100%" />
            </div>
            <div class="col-12 ">
              <Button @click="submitContactForm()" label="Submit" style="float:right; background-color: black; color: white; width: 100%"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'ContactBanner',

  props: {

  },

  mounted() {

  },

  data() {
    return {
      border: require("../../assets/images/border black.png"),
      manuka: require("../../assets/images/manuka.jpg"),
      girlsImage: require("../../assets/images/contact us.jpg"),
    }
  },

  methods: {
    submitContactForm(){
      let hasError = false;
      let errorMessage = "";
      if(this.from_name.length == 0){
        errorMessage += "Please leave your name. \n";
        hasError = true;
      }
      if(this.message.length == 0){
        errorMessage += "Please leave your message. \n";
        hasError = true;
      }
      if(this.reply_to.length == 0){
        errorMessage += "Please leave your email. \n";
        hasError = true;
      }

      if(hasError){
        alert(errorMessage);
        return(-1);
      }

      let data = {
        service_id: 'service_vxkhnlh',
        template_id: 'template_jxs7gza',
        user_id: 'lLe2MVFKFTc8W7XKW',
        template_params: {
          'from_name': this.from_name,
          'reply_to': this.reply_to,
          'message': this.message,
        }
      };

      this.axios.post("https://api.emailjs.com/api/v1.0/email/send", data)
          .then(response => function(){
            console.info(response);
          });

      this.from_name = '';
      this.message = '';
      this.reply_to = '';
      alert("Thank-you, we have received your message.");
    }

  },

  computed: {
    vuex: function(){
      return(this.$store.state);
    },

  },

  watch: {

  }

}

</script>


<style scoped>

  .dark-bg{
    position: relative;
    min-height: 400px;
    padding-top: 65px;
    padding-bottom: 40px;
    /*background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(37,37,37,1) 57%, rgba(61,61,61,1) 100%);
    */
    background-color: #F8F8F8;
    color: black;
    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center; /* align vertical */
    max-width: 100%;
    min-height: 70vh;
  }

  .girls-image{
    max-width: 100%;
  }

</style>


