<template>
  <video-banner></video-banner>
  <about-video></about-video>
  <product-banner></product-banner>
  <process-video v-if="1"></process-video>
  <contact-banner v-if="0"></contact-banner>
  <world-images></world-images>
</template>

<script>
import VideoBanner from "../components/home/VideoBanner";
import ProductBanner from "../components/home/ProductBanner";
import AboutVideo from "../components/home/AboutVideo";
import ProcessVideo from "../components/home/ProcessVideo";
import WorldImages from "../components/home/WorldImages";
import ContactBanner from "../components/home/ContactBanner";

export default {
  name: "Home",

  components: {
    ContactBanner,
    WorldImages,
    AboutVideo,
    ProcessVideo,
    ProductBanner,
    VideoBanner,
  },

  mounted() {},

  data() {
    return {};
  },

  methods: {
    updateSize: function () {
      this.maxContentHeight = this.vuex.viewHeight - this.vuex.navbarHeight - 1;
    },
  },

  computed: {
    vuex: function () {
      return this.$store.state;
    },
  },

  watch: {},
};
</script>

<style scoped></style>
