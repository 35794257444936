<template>
  <div class="wrapper center-text">
    <h2 class="top-padding">Generate QR Code</h2>
    <p>Batch Number</p>
    <InputText type="text" v-model="code" />
    <br>
    <br>
    <Button @click="generateQR(code.trim())" label="Generate" style="background-color: black; color: white;"/>

    <br />
    <br />
    <br />


    <canvas id="qrcode" style="max-width: 100%"></canvas>



    <br />
    <br />
    <br />

    <!--<p>test</p>
    <img :src="qr" alt="Wero Honey" style="margin: 0 auto; "/>
    -->
  </div>




</template>

<script>

export default {
  name: 'Generateqr',

  components: {

  },

  mounted() {
    let externalScript = document.createElement('script')
    externalScript.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/qrious/4.0.2/qrious.min.js')
    document.head.appendChild(externalScript)
  },

  data() {
    return {
      qr: require("../assets/images/300-mgo-qr-template.png"),
      code: "",
      buttonHasBeenCLicked: false,
    }
  },

  methods: {
    generateQR: function(code){
      const hyperref = "https://werohoney.co.nz/trace/" + code;
      let settings = {
        element: document.getElementById("qrcode"),
        value: hyperref,
        size: 512
      }
      new window.QRious(settings);
      return(0);
    },
},

  computed: {
    vuex: function () {
      return(this.$store.state);
    },
  },

  watch: {

  }
}
</script>

<style scoped>

</style>
