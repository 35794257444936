<template>

  <div class="wrapper center-text">
    <h2 class="top-padding">ABOUT OUR HONEY</h2>
  </div>

  <div class="grid">
    <div class="col-12 md:col-5">
      <div class="video-container">
        <iframe class="how-its-made-video" width="100%" height="100%" src="https://www.youtube.com/embed/01VbdDiFUoA?rel=0&modestbranding=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <h3 class="info-title">How It's Made</h3>
      <p class="info-text bottom-padding">We love taking care of our bees, they are the hardest workers at Wero! Our hives are placed in a range of rural locations in beautiful Aotearoa. When they are ready to be harvested, our other (human) team of ‘worker bees’ go out to the field to carefully collect the honeycomb and make sure our bees are happy.</p>
      <p class="info-text bottom-padding">The second stage of making delicious Wero Mānuka honey is the extraction process. This takes place at our New Zealand extraction plant in which the honey is separated from the wild honeycomb. Once separated, the honey is tested for quality to ensure its up to high Wero standards.</p>
      <p class="info-text bottom-padding">Once all of our honey standards have been met, the new wero honey is attentively stored in our special temperature-controlled room. This process is extremely important as maturing the honey increases it's taste, quality and MGO content. The honey will stay here for 12 - 18 months.</p>
      <p class="info-text bottom-padding">Fast-forward through the storage period and the latest batch of Wero honey undergoes testing by an independent, accredited laboratory. We think our honey is great but believe in having all Wero products quality tested independently to ensure you love it as much as we do! Natural markers in the honey will indicate which jar and quality label the honey will end up in for you to enjoy.</p>
      <p class="info-text bottom-padding">Finally, after a patient and dedicated process our delicious Wero honey makes it into jars, right here in Aotearoa. The jars are made in New Zealand using BPA-free recycled materials so that your Wero honey is delivered to you safely to enjoy!</p>
    </div>

    <div class="col-0 md:col-2 hidden-on-mobile">
      <Divider layout="vertical" />
    </div>

    <div class="col-12 md:col-5">
      <h3 class="info-title">What is MGO?</h3>
      <p class="info-text bottom-padding">The active ingredient in Mānuka honey is methylglyoxal (MGO). The higher the MGO, the higher the levels of enzymes and antibacterial properties, making it beneficial to those who consume it regularly or use it as a topical treatment on wounds and cuts. And let’s not forget the unmistakable taste and smooth velvety texture that is characteristic to Mānuka honey.</p>

      <h3 class="info-title">Quality Guarantee</h3>
      <p class="info-text">All Mānuka honey for export from New Zealand must be independently tested and pass the Mānuka Honey Science Definition as defined by <a href="https://www.mpi.govt.nz/food-business/honey-bee-products-processing-requirements/manuka-honey-testing/" target="_blank">Ministry for Primary Industries (MPI)</a>. Testing is is made up of a combination of 5 attributes. The honey must pass all five tests to be labeled as Mānuka.</p>
      <p class="info-text bottom-padding">Additionally, all Wero Mānuka honey undergoes testing by an independent laboratory, straight from the hive and again every 6 months while the honey ages, until the honey is packed into jars.</p>

      <h3 class="info-title">How we Grade our Honey</h3>
      <p class="info-text">Every jar of Wero honey is graded according to it's MGO content. You may have noticed there is a second rating called UMF (Unique Mānuka Factors). These ratings have an equivalence to them, see our chart for the conversions.</p>
      <p class="info-text">It should also be noted that when you get our honey you are actually getting the highest rating of the two. For example, our 800+ jar is also rated at 20+ UMF, so we ensure that the honey within actually contains at least 829 MGO.</p>
      <img :src="chart" class="chart"/>
      <p class="informational-text center-text chart-caption">Conversion Chart</p>
    </div>


  </div>
</template>

<script>
export default {
  name: 'MgoSection',

  props: {

  },

  mounted() {

  },

  data() {
    return {
      chart: require("../../assets/images/conversion chart.jpg"),
    }
  },

  methods: {

  },

  computed: {
    vuex: function(){
      return(this.$store.state);
    },

  },

  watch: {

  }

}

</script>


<style scoped>

.wrapper{
  padding-bottom: 2rem;
}

.info-title{
  font-family: acumin-pro, sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 1.4rem;
  text-align: justify;
}
.info-text{
  font-family: acumin-pro, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 1.4rem;
}

.video-container{
  width: 1100px;
  height: 400px;
  max-width: 100%;
  max-height: 100%;
}

.chart{
  max-width: 100%;
  padding: 1rem 1rem 0rem 1rem;
}
.chart-caption{
  padding: 0;
  margin-top: 0;
}

@media screen and (max-width: 767px){
  .hidden-on-mobile{
    display: none;
  }
}



</style>


