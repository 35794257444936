<template>
  <div class="grid grid-nogutter justify-content-center dark-bg" id="story">
    <div class="border-test" :style="'background-image: url(\'' + border + '\');'"></div>

    <div class="col-12 md:col-10 product-banner-wrapper">
      <div class="grid justify-content-center edge-padding center-vertical">
        <div class="col-12 lg:col-6 lg:flex-order-0 flex-order-1 lg:pr-4" data-aos="fade-up" data-aos-once="true">
          <div :style="centerOnMobile">
            <h2>Authentic Mānuka Honey</h2>
          </div>
          <div v-if="vuex.isMobile">
            <iframe
              width="1080"
              height="420"
              src="https://www.youtube.com/embed/01VbdDiFUoA?rel=0&modestbranding=1"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              class="about-us-video"
            ></iframe>
          </div>
          <p class="paragraph-text">
            We care for our hardworking bees in rural locations across beautiful Aotearoa. Our team collects the honeycomb with utmost care, ensuring
            the bees' well-being. The honey is then separated and rigorously tested for quality, meeting our high standards. It is stored in a
            temperature-controlled room for 12-18 months to enhance taste, quality, and MGO content.
          </p>
          <p class="paragraph-text">
            Wero honey undergoes independent testing by accredited laboratories, ensuring its exceptional quality. We believe in independent quality
            testing for all Wero products to ensure your satisfaction. Natural markers in the honey determine the jar and quality label, providing you
            with a delightful experience.
          </p>
          <p class="paragraph-text">
            Our delicious Wero honey is carefully packaged in jars made in New Zealand from BPA-free recycled materials, guaranteeing safe delivery
            for you to savor.
          </p>
        </div>
        <div v-if="!vuex.isMobile" class="col-12 lg:col-6 lg:flex-order-1 flex-order-0 lg:pl-4" data-aos="fade-left" data-aos-once="true">
          <iframe
            width="1080"
            height="420"
            src="https://www.youtube.com/embed/01VbdDiFUoA?rel=0&modestbranding=1"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            class="about-us-video"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProcessVideo",

  props: {},

  mounted() {},

  data() {
    return {
      border: require("../../assets/images/border.png"),
    };
  },

  methods: {
    routeToAbout: function () {
      // Change to about page - and scroll to top !
      this.$router.push({ name: "About" });
      window.scrollTo(0, 0);
    },

    scrollDown: function () {
      // Scrolls the page to the products section
      let products = document.getElementById("productBanner");
      if (products) {
        window.scrollTo({ top: products.offsetTop, behavior: "smooth" });
      }
    },
  },

  computed: {
    vuex: function () {
      return this.$store.state;
    },

    centerOnMobile: function () {
      if (this.vuex.isMobile) {
        return "width: 100%; display: flex; justify-content: center;";
      } else {
        return "width: 100%";
      }
    },
  },

  watch: {},
};
</script>

<style scoped>
.dark-bg {
  position: relative;
  padding-top: 65px;
  padding-bottom: 40px;
  /*background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(37,37,37,1) 57%, rgba(61,61,61,1) 100%);
    */
  background-color: black;
  color: whitesmoke;
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
  max-width: 100%;
  min-height: 70vh;
}

.black-bg-only {
  background-color: black;
}

.product-banner-wrapper {
  padding-bottom: 0;
}

.about-us-video {
  width: 100%;
  margin-top: 15px;
  box-shadow: 1px 1px 19px rgb(0 0 0 / 30%);
  border-style: solid;
  border-width: 13px;
  border-color: #ffffff;
  background-color: transparent;
}

.world-image {
  max-width: 100%;
}

.center-text {
  text-align: center;
}

.center-vertical {
  align-items: center;
}

.mobile-button {
  width: 100%;
}

.test-image {
  height: 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
</style>
