<template>
  <div class="grid grid-nogutter justify-content-center dark-bg" id="story">
    <div class="border-test" :style="'background-image: url(\'' + border + '\');'"></div>

    <div class="col-12 md:col-10 product-banner-wrapper">
      <div class="grid justify-content-center edge-padding center-vertical">
        <div class="col-12 lg:col-6 lg:pr-4 flex-order-0" data-aos="fade-up" data-aos-once="true">
          <div :style="centerOnMobile">
            <h2>Who We Are, Our Story</h2>
          </div>

          <div v-if="vuex.isMobile">
            <iframe
              data-aos="fade-right"
              data-aos-once="true"
              width="1080"
              height="420"
              src="https://www.youtube.com/embed/RKYu_w_k1Mg?rel=0&modestbranding=1"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              class="about-us-video"
            ></iframe>
          </div>

          <p class="paragraph-text">
            Wero New Zealand Mānuka Honey are very proud to be doing what we are in the NZ honey industry. We are a Māori/indigenous whānau (family)
            owned & operated business who strives to put our authentic blend of Mānuka honey, the beautiful Wairarapa, Māori culture, our local people
            and our beautiful whenua (land) on a global platform.
          </p>
          <p class="paragraph-text" style="display: none">
            Wero NZ Mānuka Honey is a 100% indigenous family owned business, born and bred in the beautiful Wairarapa on the east coast of New
            Zealand. We are tangata whenua (people of the land). We welcome you to experience our cultural connection to the whenua (land) and its
            many taonga (treasures), including the Mānuka plant, which allows us to produce authentic Mānuka honey for us to share with the world. As
            Kaitiaki (guardian) it is our responsibility to protect the whenua and those taonga for future generations.
          </p>
          <p class="paragraph-text">
            Wero was born in 2017 when Paddy & Charmaine Mason had a passionate vision to create delicious Mānuka honey whilst providing
            opportunities, resources, training and employment to their local community.
          </p>
          <p class="paragraph-text">
            Fast forward to the present and Paddy, Charmaine and the Wero whanau (family) are beyond proud to now have an established business that
            will continue to grow and evolve. At were we strive to create and maintain our original vision of a unique sustainable brand with our
            Authentic Mānuka Honey on the International Market.
          </p>
          <div :style="centerOnMobile">
            <Button
              @click="routeToAbout()"
              label="Read More"
              icon="pi pi-arrow-circle-right"
              iconPos="right"
              class="p-button-lg wero-button"
            ></Button>
          </div>
        </div>
        <div v-if="!vuex.isMobile" class="col-12 lg:col-6 lg:pl-4 flex-order-1">
          <iframe
            width="1080"
            height="420"
            src="https://www.youtube.com/embed/RKYu_w_k1Mg?rel=0&modestbranding=1"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            class="about-us-video"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutVideo",

  props: {},

  mounted() {},

  data() {
    return {
      border: require("../../assets/images/border black.png"),
    };
  },

  methods: {
    routeToAbout: function () {
      // Change to about page - and scroll to top !
      this.$router.push({ name: "About" });
      window.scrollTo(0, 0);
    },

    scrollDown: function () {
      // Scrolls the page to the products section
      let products = document.getElementById("productBanner");
      if (products) {
        window.scrollTo({ top: products.offsetTop, behavior: "smooth" });
      }
    },
  },

  computed: {
    vuex: function () {
      return this.$store.state;
    },

    centerOnMobile: function () {
      if (this.vuex.isMobile) {
        return "width: 100%; display: flex; justify-content: center;";
      } else {
        return "width: 100%";
      }
    },
  },

  watch: {},
};
</script>

<style scoped>
.dark-bg {
  position: relative;
  padding-top: 65px;
  padding-bottom: 40px;
  /*background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(37,37,37,1) 57%, rgba(61,61,61,1) 100%);
    */
  background-color: #f8f8f8;
  color: black;
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
  max-width: 100%;
  min-height: 70vh;
}

.black-bg-only {
  background-color: black;
}

.product-banner-wrapper {
  padding-bottom: 0;
}

.about-us-video {
  width: 100%;
  margin-top: 15px;
  box-shadow: 1px 1px 19px rgb(0 0 0 / 30%);
  border-style: solid;
  border-width: 13px;
  border-color: #ffffff;
  background-color: transparent;
}

.world-image {
  max-width: 100%;
}

.center-text {
  text-align: center;
}

.center-vertical {
  align-items: center;
}

.mobile-button {
  width: 100%;
}

.test-image {
  height: 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
</style>
