<template>
  <div class="wrapper center-text">
    <h2 class="top-padding bottom-padding">OUR VALUES</h2>
  </div>

  <div class="grid bottom-padding" style="align-items: stretch">

    <div class="col-12 md:col-6 lg:col-4 values-card" data-aos="zoom-in" data-aos-once="true">
      <Card>
        <template #header>
          <img :src="customsImage" class="img-rounded img-border"/>
        </template>
        <template #title>
          <span class="maori-word">Tikanga</span><br>
          <span class="english-translation">(Customs)</span>
        </template>
        <template #content>
          <p class="values-card-p">Our philosophy is to create an authentic Mānuka honey that reflects our Whānau (family), Whenua (land), and Iwi (Tribe). Our vision is to be indigenous leaders in the global market with an ethos that follows best practices for quality and sustainability within each of our products.</p>
        </template>
      </Card>
    </div>

    <div class="col-12 md:col-6 lg:col-4 values-card" data-aos="zoom-in" data-aos-once="true">
      <Card>
        <template #header>
          <img :src="landImage" class="img-rounded img-border"/>
        </template>
        <template #title>
          <span class="maori-word">Whenua</span><br>
          <span class="english-translation">(Land)</span>
        </template>
        <template #content>
          <p class="values-card-p">At Wero we nurture and harvest Mānuka honey from some of the most pristine and remote areas of Aotearoa/New Zealand. We understand the importance of respecting mother nature. If we protect and preserve the native habitat, nature will continue to provide.
          </p>
        </template>
      </Card>
    </div>

    <div class="col-12 md:col-6 lg:col-4 values-card" data-aos="zoom-in" data-aos-once="true">
      <Card>
        <template #header>
          <img :src="honeyImage" class="img-rounded img-border"/>
        </template>
        <template #title>
          <span class="maori-word">Whakatūturu</span><br>
          <span class="english-translation">(Authenticity)</span>
        </template>
        <template #content>
          <p class="values-card-p">Aotearoa/New Zealand Mānuka Honey is one of the world's finest premium quality honeys. For generations, the Māori people of Aotearoa/New Zealand have used the Mānuka tree for its unique properties as food source and as rongoā/medicine for health and wellbeing.  </p>
        </template>
      </Card>
    </div>

    <div class="col-12 md:col-6 lg:col-4 values-card" data-aos="zoom-in" data-aos-once="true">
      <Card>
        <template #header>
          <img :src="guardianImage" class="img-rounded img-border"/>
        </template>
        <template #title>
          <span class="maori-word">Kaitiakitanga</span><br>
          <span class="english-translation">(Guardian)</span>
        </template>
        <template #content>
          <p class="values-card-p">Here at Wero, our bee keeping practices are reflected in the quality of our honey. Our passion for responsible native beekeeping and focus on ethical and sustainable practices to care for the whenua/land, mean that we can all enjoy the benefits today and for future generations.</p>
        </template>
      </Card>
    </div>

    <div class="col-12 md:col-6 lg:col-4 values-card" data-aos="zoom-in" data-aos-once="true">
      <Card>
        <template #header>
          <img :src="relationshipsImage" class="img-rounded img-border"/>
        </template>
        <template #title>
          <span class="maori-word">Whanaungatanga</span><br>
          <span class="english-translation">(Relationships)</span>
        </template>
        <template #content>
          <p class="values-card-p">Wero is a family business and therefore we highly value our inclusive relationships based on respect, trust and honesty. We actively work with whānau/family, passionate partners and our local community to ensure our land is respected and our honey is authentic and delicious.</p>
        </template>
      </Card>
    </div>

    <div class="col-12 md:col-6 lg:col-4 values-card" data-aos="zoom-in" data-aos-once="true">
      <Card>
        <template #header>
          <img :src="nurtureImage" class="img-rounded img-border"/>
        </template>
        <template #title>
          <span class="maori-word">Manaakitanga</span><br>
          <span class="english-translation">(Nurture)</span>
        </template>
        <template #content>
          <p class="values-card-p">Our focus on sustainable practices mean that we value the care and connection we have to the whēnua/land. We are conscious of our businesses' impact and care deeply for the welfare and wellbeing of all our staff, including our bees!</p>
        </template>
      </Card>
    </div>


  </div>



</template>

<script>
export default {
  name: 'ValuesSection',

  props: {

  },

  mounted() {

  },

  data() {
    return {
      customsImage: require("../../assets/images/customs.jpg"),
      landImage: require("../../assets/images/land.png"),
      honeyImage: require("../../assets/images/manuka-bee.png"),
      guardianImage: require("../../assets/images/Guardian.jpg"),
      relationshipsImage: require("../../assets/images/Relationships.jpg"),
      nurtureImage: require("../../assets/images/Honey.jpg"),
    }
  },

  methods: {


  },

  computed: {
    vuex: function(){
      return(this.$store.state);
    },


  },

  watch: {

  }

}

</script>


<style scoped>

.values-card{
  max-width: 100% !important;
}

.values-card > .p-card{
  border: none;
  box-shadow: none;
  text-align: center;
}

.values-card-p{
  text-align: justify;
}

.img-border{
  box-shadow: 1px 1px 19px rgb(0 0 0 / 30%);
  border-style: solid;
  border-width: 13px;
  border-color: #FFFFFF;
  background-color: transparent;
}

.heading{
  padding-bottom: 45px;
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: flex-start;; /* align top */
}

.maori-word{
  font-weight: 600;
}
.english-translation{
  font-weight: 400;
  font-size: 1rem;
}

</style>


