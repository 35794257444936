<template>
  <div id="productBanner" class="grid grid-nogutter justify-content-center dark-bg product-section " :style="'background-image: url(\'' + productBanner + '\')'">
    <div class="border-test" :style=" 'background-image: url(\'' + border + '\');' "></div>

    <div class="col-12 md:col-10 lg:col-10 heading">
      <h2>Our Honey</h2>
    </div>
    <div class="col-12 md:col-10 lg:col-10 jars" data-aos="zoom-out" data-aos-once="true">
      <img v-if="!vuex.isMobile" :src="productsLarge" class="products-image" />
      <img v-if="vuex.isMobile" :src="productsSmall" class="products-image" />
    </div>
    <div class="col-12 md:col-10 lg:col-10">
      <div class="product-button-wrapper">
        <Button @click="routeToProducts()" label="Learn More" icon="pi pi-arrow-circle-right" iconPos="right" class="p-button-lg wero-button"></Button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductBanner',

  props: {

  },

  mounted() {

  },

  data() {
    return {
      border: require("../../assets/images/border.png"),
      productBanner: require("../../assets/images/honey-river.jpg"),
      productsLarge: require("../../assets/images/products-large.png"),
      productsSmall: require("../../assets/images/products-small.png"),

    }
  },

  methods: {
    routeToProducts: function(){
      // Change to products page - and scroll to top !
      this.$router.push({name: "Products"});
      window.scrollTo(0,0);
    }
  },

  computed: {
    vuex: function(){
      return(this.$store.state);
    },

  },

  watch: {

  }

}

</script>


<style scoped>

  .product-section{
    min-height: 400px;
    padding-top: 15px;
    padding-bottom: 45px;
    background-colour: whitesmoke;
    display: flex;
  }

  .heading{
    color: whitesmoke;
    padding-top: 30px;
    display: flex;
    justify-content: center; /* align horizontal */
    align-items: flex-start;; /* align top */
    text-shadow: rgb(17 17 17 / 83%) 5px 0px 5px;
  }

  .product-button-wrapper{
    display: flex;
    justify-content: center; /* align horizontal */
    align-items: flex-end; /* align bottom */
  }

  .products-image{
    width: 100%;
  }

  .product-banner{
    max-width: 100%;
    margin-bottom: 15px;
  }

  .product-section{
    min-height: 100vh;
    background-position: center;
    background-size: cover;
  }

  .wero-button{
    -webkit-box-shadow: 2px 2px 5px 0px rgba(1, 1, 1, 0.75);
    -moz-box-shadow: 2px 2px 5px 0px rgba(1, 1, 1, 0.75);
    box-shadow: 2px 2px 5px 0px rgba(1, 1, 1, 0.75);
    text-align: center;
    border: 0px solid black;
  }

  h2{
    text-shadow: rgb(0 0 0 / 83%) 5px 0px 5px;
  }

  .dark-bg{
    position: relative;
    padding-top: 65px;
    padding-bottom: 40px;
    /*background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(37,37,37,1) 57%, rgba(61,61,61,1) 100%);
    */
    background-color: black;
    color: whitesmoke;
  }





</style>


