<template>
  <div class="wrapper">
    <navigation-bar></navigation-bar>
    <router-view></router-view>
    <footer-bar></footer-bar>
  </div>
</template>

<script>


import NavigationBar from "./components/NavigationBar";
import FooterBar from "./components/FooterBar";

export default {
  name: 'App',

  components: {
    NavigationBar,
    FooterBar
  },

  mounted() {
    // This code makes sure that vuex has live access to elements width's and heights, for responsive design
    this.storeResponsiveParams();
    const self = this;
    window.addEventListener("resize", function() {
      this.storeResponsiveParams();
    }.bind(self));

    window.addEventListener("load", function() {
      this.storeResponsiveParams();
    }.bind(self));

  },

  data() {
    return {

    }
  },

  methods: {
    storeResponsiveParams: function(){
      // Store some important variables to vuex, like window dimensions and if it is mobile (smaller than bootstrap lg class)
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;
      const isMobile = (windowWidth < 991);
      const navFooterCombinedHeight = document.getElementById("footerBar").clientHeight + document.getElementById("menuBar").clientHeight;

      // Update the global state
      this.$store.commit({
        type: 'updateSizes',
        width: windowWidth,
        height: windowHeight,
        isMobile: isMobile,
        navFooterCombinedHeight: navFooterCombinedHeight
      });
    },

  },

  computed: {
    vuex: function () {
      return(this.$store.state);
    },
  },

  watch: {

  }
}
</script>

<style scoped>

  .wrapper{
    max-width: 100%;
    overflow: hidden;
    min-height: 100vh;

  }


</style>
